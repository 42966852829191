import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import grey from '@material-ui/core/colors/grey';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {AuthContext} from '../auth/AuthContext';
import { StateHelper } from 'react-form-plus';
import {crud} from '../services/crud';
import {withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
    compcontainer: {
        backgroundColor: '#ffffff',
        height: '100vh', 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        backgroundColor: '#f26522',
    },
    header: {
        textAlign: 'center',
        margin: '0.5em'
    },
    input: {
        backgroundColor: theme.palette.common.white,
        margin: '5px 0'
    },
    formControl: {
        backgroundColor: theme.palette.common.white,
        margin: '1em 0'
      },
      button: {
        margin: '10px auto',
        display: 'block'
      },
      loader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
  }));

  const years = [];

  for(var i = 2019; i >= 1970;i-=1) {
    years.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
  }

const Contact = (props) => {
    let {user} = React.useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const contacts = crud('contacts');

   if(!user || Object.keys(user).length <= 0) {
    user =  {meta: {}}
   }

    const [contact, setContact] = React.useState({
        name: `${(user.meta.firstName && user.meta.lastName) ? `${user.meta.firstName} ${user.meta.lastName}` : ''}`,
        phone: user.meta.phone ? `+63${user.meta.phone}` : '',
        subject: '',
        message: ''
    });

    fs.useState(contact, setContact);
    const classes = useStyles();

    const submitContact = async () => {
        setIsLoading(true);
        let res = await contacts.save(contact);
        if(res.data) {
            props.history.push('/contact/confirm');
        } else {
            console.log("Something went wrong!");
        }
    }

    const handleSelectChange = (e, model) => {
        fs.setState({
            [model]: e.target.value
        });
    }

    return(
        <div className="content-paper">
            <Typography color="primary" className={classes.header} variant="h5">
                Contact Us
            </Typography>
            <Typography style={{margin: '10px 0'}}>For immediate attention, you can reach us through <a style={{textDecoration: 'none', color: '#f26522'}} href="tel:+639085286364">+639085286364</a>. </Typography>
            <TextField type="text" id="outlined-basic" className={classes.input} label="Name" variant="filled" fullWidth {...fs.model('name')}/>
            <TextField type="text" id="outlined-basic" className={classes.input} label="Phone Number" variant="filled" fullWidth {...fs.model('phone')}/>
            <TextField id="outlined-basic" className={classes.input} label="Subject" variant="filled" fullWidth {...fs.model('subject')}/>
            <TextField
                id="filled-multiline-static"
                label="Message"
                multiline
                rows="5"
                className={classes.input}
                variant="filled"
                fullWidth
                {...fs.model('message')}
            />
            {
                isLoading ? 
                    <div className={classes.loader}><CircularProgress /></div>
                :
                <Button variant="contained" color="primary" className={classes.button} onClick={submitContact}>
                    Submit
                </Button>
            }
        </div>
    )
}

export default withRouter(Contact);