import React, {Suspense} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';


const RegisterForm = React.lazy(() => import('../auth/RegisterForm'));

const useStyles = makeStyles(theme => (
    {
    container: {
        backgroundColor: '#ffffff',
        height: '100vh', 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        backgroundColor: '#f26522',
    },
    header: {
        color: grey[100],
        textAlign: 'center',
        margin: '0.5em'
    },
    input: {
        backgroundColor: theme.palette.common.white,
        margin: '1em 0'
    },
    formControl: {
        backgroundColor: theme.palette.common.white,
        margin: '1em 0'
      },
     loginButton: {
        backgroundColor: theme.palette.common.white,
        color: '#f26522',
        marginLeft: 'auto',
        display: 'block'
      },
      button: {
        margin: '0.5em 0'
      },
      divider: {
          margin: '2em 0',
          backgroundColor: theme.palette.common.white
      },
      icon: {
          maxHeight: '1.5em',
          margin: '0 1em'
      }
  }));

const Register = () => {
    const classes = useStyles();
    return(
        <div className={classes.container}>         
            <Container maxWidth="xs">
            <Card className={classes.card}>
                <CardContent>
                    <Typography className={classes.header} variant="h5">
                       Register
                    </Typography>
                    <Suspense fallback={<CircularProgress/>}>
                        <RegisterForm />
                    </Suspense>
                </CardContent>
             </Card>
            </Container>
        </div>
    )
}

export default Register;