import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {crud} from '../services/crud';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import {format} from 'date-fns';
import {withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { StateHelper } from 'react-form-plus';
import TextField from '@material-ui/core/TextField';

const fs = new StateHelper();

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    container: {
        padding: '4em 0',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#ffffff'
    }
  });

const AdminSettings = (props) => {
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [edit, setEdit] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [msg, setMsg] = React.useState('');
    const [settings, setSettings] = React.useState({});
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const classes = useStyles();
    const _settings = crud('settings');

    fs.useState(settings, setSettings);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const getSettings = async () => {
        let res = await _settings.find({_sort: "key:ASC", _limit: 1000});
        if(res) {
           let _rows = [];
           let s = {};
           res.data.forEach(element => {
              _rows.push({
                  id: element._id,
                  key: element.key,
                  value: element.value,
                  date: element.updatedAt
              });
              s[element.key] = element.value;
           });
           setRows(_rows);
           setSettings(s);
        }
    }

    const saveSettings = () => {
      Object.keys(settings).forEach(async (el) => {
       if(el !== '_errors') {
        const idx = rows.findIndex(x => x.key === el);
        if(rows[idx].value !== settings[el]) {
          const res = await _settings.save({_id: rows[idx].id , key: el, value: settings[el]});
            setEdit(false);
            getSettings();
        }
       }
        
      });
    }

    const handleChangePage = (event, page) => {
      setPage(page);
    }

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }

    React.useEffect( () => {
        getSettings();
    },[]);
return(
<div className={classes.container}>
  <Container component={Paper} style={{padding: '1.5em'}}>
    <Table className={classes.table} size="small" aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Key</StyledTableCell>
          <StyledTableCell>Value</StyledTableCell>
          <StyledTableCell>Updated At</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((row, idx) => (
          <StyledTableRow hover key={idx}>
            <StyledTableCell style={{height: '70px'}}>{row.key}</StyledTableCell>
            <StyledTableCell>{edit ? <TextField type="text" id="outlined-basic" {...fs.model(`${row.key}`)} variant="outlined" fullWidth size="small"/> : row.value }</StyledTableCell>
            <StyledTableCell>{format(new Date(row.date), "MMM. dd, yyyy hh:mm a")}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
    {edit ?
      <React.Fragment>
        <Button onClick={()=>{saveSettings()}} style={{background: '#f26522', color: '#fff', margin: '1em 0'}} variant="contained">Save</Button>
        <Button onClick={()=>{setEdit(false)}} style={{background: '#ffffff', color: '#f26522', margin: '1em 0.5em 1em'}} variant="contained">Cancel</Button>
      </React.Fragment>
    :
      <Button onClick={()=>{setEdit(true)}} style={{background: '#f26522', color: '#fff', margin: '1em 0'}} variant="contained">Edit</Button>
    }
    {rows.length > 20 &&
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    }

  </Container>
  </div>
    )
};

export default withRouter(AdminSettings);