import React, {useState, useEffect} from "react";
import cache from '../services/cache.js';
import http from '../services/http';
import config from '../config/config';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
    let me = cache.get('user', {meta: {}});
    if(me.token) {
      http.defaults.headers.common["Authorization"] = "Bearer " + me.token;
    }
    const [user, setUser] = useState(me);

    const putUser = (_user) => {
      cache.put('user', _user, {
        persist: true
      });
      setUser(_user); 
    }

    const logout = async () => {
      putUser({});
      delete http.defaults.headers.common['Authorization'];
      setTimeout(()=>{
        window.location.reload();
      }, 1500);
    }

    const whoAmI = async () => {
      if(Object.keys(me).length > 0) {
        await http
        .get(config.app.server.url + "/auth-otp/me")
        .then(res => {
          putUser({...user, ...res.data});
        })
        .catch(err => {
          if(err.response) {
            if(err.response.data.statusCode == 401) {
              logout();
            }
          }
        });
      }
    }

    useEffect(()=>{
      whoAmI();
    },[]);

    return (
        <AuthContext.Provider value={{user: user, setUser: putUser, whoAmI: whoAmI}}>
            {props.children}
        </AuthContext.Provider>
    )
}
export {AuthContext, AuthProvider};