import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StateHelper } from 'react-form-plus';
import { OrderContext } from '../store/OrderContext';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import grey from "@material-ui/core/colors/grey";
import { SettingsContext } from '../store/SettingsContext';
import TextField from '@material-ui/core/TextField';
import { AuthContext } from '../auth/AuthContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import {
    MuiPickersUtilsProvider,
    DatePicker,
    TimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import addressList from '../assets/address.json';
import airportList from '../assets/airports.json';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { differenceInMinutes, format } from 'date-fns';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
            },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
            },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '17px',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
        },
        descriptionText: {
            fontSize: '1.5em',
            margin: theme.spacing(2),
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        // formControl: {
        //     margin: "1em 0",
        //     background: theme.palette.common.white
        //   },
        descriptionCont: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
            }
        },
        toggleButtons: {
            display: 'flex',
            marginBottom: '5px'
        },
        toggleButton: {
            flex: 1,
            textAlign: 'center',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                cursor: 'pointer',
            },
            '&:hover .MuiTypography-root': {
                color: '#f26522'
            },
            '&:first-child': {
                borderRadius: '8px 0 0 8px',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:last-child': {
                borderRadius: ' 0 8px 8px 0',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderRight: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:not(:first-child):not(:last-child)': {
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:first-child:hover': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&:first-child:hover + &': {
                borderLeft: 'none'
            },
            '&:not(:first-child):not(:last-child):hover': {
                border: '2px solid #f26522',
            },
            '&:not(:first-child):not(:last-child):hover + &': {
                borderLeft: 'none'
            },
            '&:last-child:hover': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            },
            '&.active .MuiTypography-root': {
                color: '#f26522'
            },
            '&.active:first-child': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&.active:first-child + &': {
                borderLeft: 'none'
            },
            '&.active:not(:first-child):not(:last-child)': {
                border: '2px solid #f26522',
            },
            '&.active:not(:first-child):not(:last-child) + &': {
                borderLeft: 'none'
            },
            '&.active:last-child': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            }
        },
        timePicker: {
            '& .MuiDialog-container .MuiPickersClockNumber-clockNumber': {
                // display: "none"
            }
        },
        buttons: {
            textTransform: "none"
        }
    }));

const Address = props => {
    const classes = useStyles();
    const { user } = React.useContext(AuthContext);
    const _order = React.useContext(OrderContext);
    const [errors, setErrors] = React.useState([]);
    const [state, setState] = React.useState({ ..._order.order });
    const [totalItems, setTotalItems] = React.useState(0);
    const [haveBelly, setHaveBelly] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
    fs.useState(state, setState);

    const checkCounters = () => {
        let lechonCounter = 0;
        let bellyCounter = 0;

        state.items.forEach(i => {
            if (i.type == 'whole') {
                lechonCounter += i.qty;
            } else if (i.type == 'belly') {
                bellyCounter += i.qty;
            }
        });

        return { belly: bellyCounter, lechon: lechonCounter };
    }

    const counters = checkCounters();

    const allowedCity = [
        "CEBU CITY",
        "CONSOLACION",
        "CORDOVA",
        "LAPU-LAPU CITY",
        "LILOAN",
        "MANDAUE CITY",
        "MINGLANILLA",
        "NAGA CITY",
        "TALISAY CITY"
    ];

    const checkSubmit = () => {
        let err = [];
        Object.keys(state.meta).forEach((key) => {
            if (state.meta[key] === '') {
                if (key == 'unit') {
                    return;
                }
                if (state.meta.type == "cebu" && key == 'airport') {
                    return;
                }
                if (state.meta.type == "airport" && key != 'airport') {
                    return;
                }
                if (state.meta.type == "pickup") {
                    return;
                }
                err.push(key);
            }
        });

        setErrors(err);

        if (err.length === 0) {
            checkout();
        }

    }

    const checkout = () => {
        if (isNotValidTime()) {
            return;
        }
        let doc = { ...state };

        doc.meta.date = handleTimeOffset(doc.meta.date);
        doc.meta.time = handleTimeOffset(doc.meta.time);

        if (state.meta.type == 'cebu') {
            delete doc.meta.airport;
            delete doc.meta.fees;

            if (haveDelFee()) {
                doc.meta = {
                    ...state.meta,
                    fees: {
                        delivery: haveDelFee()
                    }
                }
            } else if (counters.belly >= 1 && counters.lechon <= 0) {
                doc.meta = {
                    ...state.meta,
                    fees: {
                        delivery: settings.fees.belly
                    }
                }
            }
        }

        if (state.meta.type == 'airport') {
            delete doc.meta.barangay;
            delete doc.meta.city;
            delete doc.meta.street;
            delete doc.meta.unit;
            delete doc.meta.fees;
            //get all qty

            let totalQty = 0;
            doc.items.forEach(i => {
                totalQty += i.qty
            });

            doc.meta = {
                ...state.meta,
                fees: {
                    freight: totalQty * settings.fees.freight
                }
            }
        }

        if (state.meta.type == 'pickup') {
            delete doc.meta.fees;
            delete doc.meta.barangay;
            delete doc.meta.city;
            delete doc.meta.street;
            delete doc.meta.unit;
            delete doc.meta.airport;
        }

        _order.setOrder(doc);
        props.history.push('/payment');
    }

    const handleTimeOffset = (d) => {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(d - tzoffset)).toISOString().slice(0, -1);

        return localISOTime;
    }

    const filterOrder = () => {
        // const d = new Date(handleDefaultTime().toLocaleString({}, {timeZone: 'Asia/Manila'}));
        const d = handleDefaultTime();


        if (user.token) {
            const meta = {
                type: 'cebu',
                date: d,
                time: d,
                city: "",
                barangay: "",
                street: "",
                unit: "",
                airport: "",
                ...user.meta.address,
                firstName: user.meta.firstName,
                lastName: user.meta.lastName,
                phone: user.username,
                ...state.meta
            };

            setState({ ...state, meta: meta });

            let _totalItem = 0;

            state.items.forEach(item => {
                _totalItem += item.qty;
                if (item.type == 'belly') {
                    setHaveBelly(true);
                }
            });

            setTotalItems(_totalItem);
        }
    }

    const handleDateChange = (e, model) => {
        fs.setState({
            [model]: e
        });
    }

    const handleSelectChange = (e, model) => {

        if (model == 'meta.city') {
            fs.setState({
                [model]: e.target.value,
                ['meta.barangay']: '',
                ['meta.street']: '',
                ['meta.unit']: ''
            });
            return;
        }

        if (model == 'meta.barangay') {
            fs.setState({
                [model]: e.target.value,
                ['meta.street']: '',
                ['meta.unit']: ''
            });
            return;
        }

        fs.setState({
            [model]: e.target.value
        });
    }

    const handleDefaultTime = () => {
        const d = new Date(new Date().toLocaleString({}, { timeZone: 'Asia/Manila' }));
        d.setHours(d.getHours() + 8);
        d.setMinutes(Math.ceil(d.getMinutes() / 10) * 10);

        if (counters.belly >= 1) {
            d.setDate(d.getDate() + 1);
            d.setHours(10);
            d.setMinutes(30);
        }
        return d;
    }

    const handleDeliveryType = type => {
        const d = handleDefaultTime();

        fs.setState({
            "meta.type": type,
            "meta.date": d,
            "meta.time": d
        });
    }

    const isNotValidTime = () => {
        const d = new Date(state.meta.date);
        const t = new Date(state.meta.time);
        const dd = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            t.getHours(),
            t.getMinutes(),
            0,
            0
        );

        if (counters.belly > 0) {
            const lead = new Date();
            lead.setDate(lead.getDate() + 1);
            lead.setHours(10);
            lead.setMinutes(30);
            lead.setSeconds(0);
            lead.setMilliseconds(0);

            console.log(dd);
            console.log(lead);

            if (dd < lead) {
                return true;
            }
        }

        const minutes = 480;

        const now = new Date(new Date().toLocaleString({}, { timeZone: 'Asia/Manila' }));

        now.setMinutes(Math.ceil(d.getMinutes() / 10) * 10);
        now.setSeconds(0);

        const diff = differenceInMinutes(dd, now);

        if (diff >= (minutes - 1) && diff > 0) {
            return false;
        }

        return true;
    }

    const haveDelFee = () => {

        const delFeeCity = settings.fees.delivery.city[state.meta.city];

        if (delFeeCity) {
            return delFeeCity;
        }

        const delFeeBarangay = settings.fees.delivery.barangay[state.meta.barangay];

        if (delFeeBarangay) {
            return delFeeBarangay;
        }

        return false;
    }

    const disableDates = (date) => {
        const dates = settings.disabledDates;
        for (const d of dates) {
            const dd = new Date(d);

            if (date.toDateString() == dd.toDateString()) {
                return true;
            }
        }
    }


    React.useEffect(() => {
        if (_order.order.total == undefined) {
            props.history.replace('/home');
        } else {
            filterOrder();
        }
    }, []);

    return (

        <div className={classes.orderContainer}>

            {state.meta &&
                <Fragment>
                    <div className="content-paper">
                        <Stepper activeStep={1} alternativeLabel>
                            <Step>
                                <StepLabel>Select Items</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Address & Delivery</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Payment</StepLabel>
                            </Step>
                        </Stepper>

                        <div>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography style={{}} variant="h4">{totalItems} {totalItems > 1 ? 'Items' : 'Item'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ textAlign: 'right' }} variant="h4">Total: &#8369;{state.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                </Grid>
                            </Grid>
                            <Typography style={{ margin: '20px 0 7px', fontWeight: '700' }} color="primary" variant="subtitle2">Delivery</Typography>
                            <ButtonGroup style={{ margin: '10px 0' }} fullWidth color="primary">
                                <Button variant={state.meta.type == "cebu" ? "contained" : null} className={classes.buttons} onClick={() => { handleDeliveryType('cebu') }}>Within Cebu</Button>
                                <Button variant={state.meta.type == "airport" ? "contained" : null} className={classes.buttons} onClick={() => { handleDeliveryType('airport') }}>Airport to Airport</Button>
                                <Button variant={state.meta.type == "pickup" ? "contained" : null} className={classes.buttons} onClick={() => { handleDeliveryType('pickup') }}>Pickup</Button>
                            </ButtonGroup>
                            {/* <div className={classes.toggleButtons}>
                                <div className={state.meta.type == "cebu" ? clsx(classes.toggleButton, "active") : classes.toggleButton} onClick={() => { handleDeliveryType('cebu') }}>
                                    <Typography variant="h6">Within Cebu</Typography>
                                </div>
                                <div className={state.meta.type == "airport" ? clsx(classes.toggleButton, "active") : classes.toggleButton} onClick={() => { handleDeliveryType('airport') }}>
                                    <Typography variant="h6">Airport to Airport</Typography>
                                </div>
                                <div className={state.meta.type === "pickup" ? clsx(classes.toggleButton, "active") : classes.toggleButton} onClick={() => { handleDeliveryType('pickup') }}>
                                    <Typography variant="h6">Pickup</Typography>
                                </div>
                            </div> */}
                            <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Date & Time</Typography>
                            <Fragment>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        disablePast
                                        inputVariant="filled"
                                        label="Date"
                                        format="MMMM dd, yyyy"
                                        value={state.meta.date}
                                        InputAdornmentProps={{ position: "start" }}
                                        onChange={(evt) => { handleDateChange(evt, 'meta.date') }}
                                        className={classes.input}
                                        margin="dense"
                                        fullWidth
                                        error={isNotValidTime()}
                                        shouldDisableDate={disableDates}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        inputVariant="filled"
                                        label="Time"
                                        format="hh:mm a"
                                        value={state.meta.time}
                                        InputAdornmentProps={{ position: "start" }}
                                        onChange={(evt) => { handleDateChange(evt, 'meta.time') }}
                                        className={classes.input}
                                        margin="dense"
                                        fullWidth
                                        minutesStep={10}
                                        DialogProps={{
                                            className: classes.timePicker
                                        }}
                                        helperText={haveBelly ? `Lead time for Lechon Belly is 24 hours.` : `Lead time is 8 hours.`}
                                        error={isNotValidTime()}
                                    />
                                </MuiPickersUtilsProvider>
                                <ListItem disableGutters>
                                    <ListItemIcon style={{ minWidth: "auto", marginRight: '5px', fontSize: '10px' }}>
                                        <InfoSharpIcon style={{ fontSize: '20px' }} color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='subtitle2'>
                                            The selected date and time are in <b>Philippine Standard Time (PST)</b>.
                                        </Typography>
                                    </ListItemText>
                                </ListItem>

                                {/* <div style={{textAlign: 'center'}}>
                                    <Typography variant='subtitle2'>Your order will be {state.meta.type == "pickup" ? 'available for pickup' : 'delivered'} on</Typography>
                                    {state.meta.date && <Typography style={{fontWeight: 'bold'}} variant='subtitle2'>{format(new Date(state.meta.date), 'MMM dd, yyyy')} {format(new Date(state.meta.time), 'hh:mm a')} (Philippine Standard Time)</Typography>}
                                </div> */}

                                {/* {
                                    <Typography style={{textAlign: 'center'}} variant='subtitle2'>
                                        The earliest delivery time of Lechon Belly is on <b>{format(addDays(new Date(), 1), "MMM. dd, yyyy")} 10:30 AM</b>.
                                    </Typography>
                                } */}
                                {
                                    state.meta.type == 'cebu' &&
                                    <Fragment>
                                        <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Address</Typography>
                                        <FormControl variant="filled" className={classes.formControl} fullWidth margin="dense">
                                            <InputLabel id="city-select-label">City/Municipality</InputLabel>
                                            <Select
                                                labelId="city-select-label"
                                                id="city-simple-select"
                                                fullWidth
                                                value={state.meta.city}
                                                onChange={(evt) => { handleSelectChange(evt, 'meta.city') }}
                                                error={errors.includes('city')}
                                            >
                                                {
                                                    allowedCity.map((c, idx) => (
                                                        <MenuItem key={idx} value={c}>{c}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {errors.includes('city') &&
                                                <FormHelperText error>
                                                    This field is required.
                                                </FormHelperText>
                                            }
                                        </FormControl>
                                        <FormControl variant="filled" className={classes.formControl} fullWidth margin="dense" disabled={!state.meta.city}>
                                            <InputLabel id="barangay-select-label">Barangay</InputLabel>
                                            <Select
                                                labelId="barangay-select-label"
                                                id="barangay-simple-select"
                                                fullWidth
                                                value={state.meta.barangay}
                                                onChange={(evt) => { handleSelectChange(evt, 'meta.barangay') }}
                                                error={errors.includes('barangay')}
                                            >
                                                {
                                                    state.meta.city &&
                                                    addressList.CEBU.municipality_list[state.meta.city].barangay_list.map((c, idx) => (
                                                        <MenuItem key={idx} value={c}>{c}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {errors.includes('barangay') &&
                                                <FormHelperText error>
                                                    This field is required.
                                                </FormHelperText>
                                            }
                                        </FormControl>
                                        <TextField type="text" id="street" className={classes.input} label="House No./Building Name/Street"
                                            error={errors.includes('street')}
                                            helperText={errors.includes('street') ? "This field is required." : ""}
                                            disabled={!state.meta.barangay}
                                            {...fs.model('meta.street')} variant="filled" fullWidth margin="dense" />

                                        <TextField type="text" id="unit" className={classes.input} label="Unit/Floor No. (Optional)"
                                            disabled={!state.meta.barangay}
                                            {...fs.model('meta.unit')} variant="filled" fullWidth margin="dense" />

                                        <ListItem disableGutters>
                                            <ListItemIcon style={{ minWidth: "auto", marginRight: '5px' }}>
                                                <InfoSharpIcon style={{ fontSize: '20px' }} color="primary" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant='subtitle2'>Delivery charges may apply.</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Fragment>
                                }
                                {
                                    state.meta.type == 'airport' &&
                                    <Fragment>
                                        <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Destination</Typography>
                                        <FormControl variant="filled" className={classes.formControl} fullWidth margin="dense">
                                            <InputLabel id="city-select-label">Airport</InputLabel>
                                            <Select
                                                labelId="city-select-label"
                                                id="city-simple-select"
                                                fullWidth
                                                value={state.meta.airport}
                                                onChange={(evt) => { handleSelectChange(evt, 'meta.airport') }}
                                                error={errors.includes('airport')}
                                            >
                                                {
                                                    airportList.map((c, idx) => (
                                                        <MenuItem key={idx} value={c.iata_code}><Chip style={{ marginRight: '8px', backgroundColor: 'rgb(207 207 207)' }} size="small" label={c.iata_code} /> {c.name} - {c.city}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {errors.includes('airport') &&
                                                <FormHelperText error>
                                                    This field is required.
                                                </FormHelperText>
                                            }
                                        </FormControl>

                                        <ListItem disableGutters>
                                            <ListItemIcon style={{ minWidth: "auto", marginRight: '5px' }}>
                                                <InfoSharpIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ style: { fontWeight: 500 } }}>
                                                Freight & Packing charges may apply (&#8369;{settings.fees.freight.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}/pieces).
                                            </ListItemText>
                                        </ListItem>
                                    </Fragment>
                                }
                                <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Contact Person</Typography>
                                <TextField type="text" id="firstname" className={classes.input} label="First Name"
                                    error={errors.includes('firstName')}
                                    helperText={errors.includes('firstName') ? "This field is required." : ""}
                                    {...fs.model('meta.firstName')} variant="filled" fullWidth margin="dense" />
                                <TextField type="text" id="name" className={classes.input} label="Last Name"
                                    error={errors.includes('lastName')}
                                    helperText={errors.includes('lastName') ? "This field is required." : ""}
                                    {...fs.model('meta.lastName')} variant="filled" fullWidth margin="dense" />
                                <TextField type="number" id="phone" className={classes.input} label="Mobile Number"
                                    error={errors.includes('phone') ? true : false}
                                    helperText={errors.includes('phone') ? "Invalid Phone Number" : ''}{...fs.model('meta.phone')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                                    }}
                                    variant="filled" fullWidth margin="dense" />
                            </Fragment>
                        </div>
                    </div>
                    <div style={{ width: '100%', padding: '20px 30px', display: 'inline-block' }}>
                        <div style={{ float: 'left', width: '50%' }}>
                            <Button style={{ float: 'right', display: 'block', margin: '0 10px' }} variant="contained" size="large" onClick={() => { props.history.push('/order'); }}>Back</Button>
                        </div>
                        <div style={{ float: 'right', width: '50%' }}>
                            <Button style={{ float: 'left', display: 'block', margin: '0 10px' }} color="primary" variant="contained" size="large" onClick={checkSubmit}>Next</Button>
                        </div>
                    </div>
                </Fragment>
            }
            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </div>
    )

}

export default withRouter(Address);
