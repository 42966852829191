import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {StateHelper} from 'react-form-plus';
import {OrderContext} from '../store/OrderContext';
import {withRouter} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const fs = new StateHelper();

const Order = props => {
    const [order, setOrder] = React.useState({batch: '1996', serial: ''});
    const _order = React.useContext(OrderContext);
    const [ordered, setOrdered] = React.useState([]);
    fs.useState(order, setOrder);
    const useStyles = makeStyles(theme => (
        {
            displayFlex: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                flexDirection: 'row',
            },
            width50: {
                width: '50%',
            },
            orderContainer: {
                background: '#021744',
                padding: '4em 1em',
                minHeight: '100vh',
                color: '#fff'
            },
            contentContainer: {
                width: '100%',
                margin: '0 auto',
            },
            propertyText: {
                fontSize: '1.5em',
                color: '#fff',
                margin: '0',
            },
            descriptionText: {
                fontSize: '1.2em',
                color: '#fff',
                margin: theme.spacing(2),
                textAlign: 'justify'
            },
            magisText: {
                fontSize: '18px',
                color: '#fff',
                fontWeight: '500',
                margin: '0',
            },
            infoText: {
                fontWeight: '700',
                fontSize: '16px',
                color: '#fff',
                margin: '0',
            },
            watchImg: {
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    width: '50%'
                },
                margin: '1em auto',
                display: 'block'
            },
            formControl: {
                margin: "1em 0",
                background: theme.palette.common.white
              },
              descriptionCont: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              },
              checkout: {
                  padding: theme.spacing(2)
              }
        }));
    const classes = useStyles();
    return (
        <Fragment>
            <div className={classes.orderContainer}>
                <Container maxWidth="md">
                    <Grid container>
                        <Grid item className={classes.descriptionCont}>
                        <h1>Welcome to our Privacy Policy</h1>
			<h3>Your privacy is critically important to us.</h3>

			<p>It is Magis Watch's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a style={{fontWeight: 700, textDecoration: 'none', color: "#fff"}} href="http://magiswatch.com">magiswatch.com</a> (hereinafter, "us", "we", or "magiswatch.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
			<p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>

						<h2>Website Visitors</h2>
			<p>Like most website operators, Magis Watch collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Magis Watch's purpose in collecting non-personally identifying information is to better understand how Magis Watch's visitors use its website. From time to time, Magis Watch may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
			<p>Magis Watch also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on http://magiswatch.com blog posts. Magis Watch only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>
			
						<h2>Gathering of Personally-Identifying Information</h2>
			<p>Certain visitors to Magis Watch's websites choose to interact with Magis Watch in ways that require Magis Watch to gather personally-identifying information. The amount and type of information that Magis Watch gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at http://magiswatch.com to provide a username and email address.</p>
			
						<h2>Security</h2>
			<p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
			
			

			
			
			
			
			
						<h2>Cookies</h2>
			<p>To enrich and perfect your online experience, Magis Watch uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.</p>
			<p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Magis Watch uses cookies to help Magis Watch identify and track visitors, their usage of http://magiswatch.com, and their website access preferences. Magis Watch visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Magis Watch's websites, with the drawback that certain features of Magis Watch's websites may not function properly without the aid of cookies.</p>
			<p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Magis Watch's use of cookies.</p>
			
			
			
						<h2>Privacy Policy Changes</h2>
			<p>Although most changes are likely to be minor, Magis Watch may change its Privacy Policy from time to time, and in Magis Watch's sole discretion. Magis Watch encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
			
			
						  
			  <h2></h2>			  
			  	<p></p>			
			
			<h2>Contact Information</h2>
					<p>If you have any questions about this Privacy Policy, please contact us via <a style={{fontWeight: 700, textDecoration: 'none', color: "#fff"}} href="mailto:admin@magiswatch.com">email</a> or <a style={{fontWeight: 700, textDecoration: 'none', color: "#fff"}} href="tel:639173256639">phone</a>.</p>
					
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )

}

export default withRouter(Order);
