import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {StateHelper} from 'react-form-plus';
import {OrderContext} from '../store/OrderContext';
import {withRouter} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {SettingsContext} from '../store/SettingsContext';

const fs = new StateHelper();

const Faq = props => {
    const [questions, setQuestions] = React.useState([]);
    const useStyles = makeStyles(theme => (
        {
            title: {
                textAlign: 'center',
                marginBottom: '1em'
            },
            question: {
                fontWeight: 700
            },
            indent: {
                marginTop: '0.25em'
            },
            innerFaqContainer: {
                padding: '3em 2em'
            },
            listQuestion: {
                display: 'flex',
                justifyContent: 'space-between'
            },
            link: {
                textDecoration: 'none',
                color: '#f26522',
                fontWeight: '800',
                '&:hover': {
                    textDecoration: 'underline'
                }
            },
            mainContainer: {
                [theme.breakpoints.down('md')]: {
                    padding: '0'
                  }
            }
        }));
        
    const classes = useStyles();
    const onClick = (e) => {
        let qs = [
            ...questions
        ];
        qs[e] = !qs[e];
        setQuestions(qs);
    }

    return (
        <Fragment>
            <div className="content-paper">
                    <Grid container>
                        <Grid item>
                        <Typography className={classes.title} variant="h4">Frequently Asked Questions</Typography>

            <List aria-label="faq list">
                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(0)}} divider>
                    <Typography className={classes.question} variant="h6">OTP and Mobile Number</Typography>
                    {questions[0] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[0]}>
                    <ListItem>
                        <div className={classes.indent}>
                            <Typography>Your mobile number will act as your primary means of identification. Keep your mobile phone within your reach as we will be sending you a One Time Pin for you to sign into LesliesExpress.ph.</Typography>
                        </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(1)}} divider>
                    <Typography className={classes.question} variant="h6">Lechon Sizes</Typography>
                    {questions[1] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[1]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>The lechon sizes and serving capacity is only an estimation and may fall outside of the stated numbers.</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(2)}} divider>
                    <Typography className={classes.question} variant="h6">Delivery Fee</Typography>
                    {questions[2] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[2]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>Delivery is free within Cebu City, Mandaue City, Guadalupe, Minglanilla, Pardo, Talisay. Delivery outside these areas may incur an additional delivery charge (Between &#8369;250 to &#8369;500).</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(3)}} divider>
                    <Typography className={classes.question} variant="h6">Airport to Airport deliveries</Typography>
                    {questions[3] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[3]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>For airport to airport deliveries, the lechon will be packed ready to be shipped via air freight. There is an additional packing and freight fee of P2,000 which includes delivery to Mactan International Airport and freight cost to the customer’s airport of choice. Once it is loaded for airfreight, we will send the airway bill to the customer’s mobile number so that they can pick up the lechon upon arrival at the airport of their designation. This is only for local flights (Philippines).</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(4)}} divider>
                    <Typography className={classes.question} variant="h6">Contact Person</Typography>
                    {questions[4] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[4]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>The contact person by default will be the user. The user can indicate a different contact person and their mobile number. Please take note that this refers to the person to look for at the delivery address.</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(5)}} divider>
                    <Typography className={classes.question} variant="h6">Peak dates limit</Typography>
                    {questions[5] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[5]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>Due to high volume of orders, some dates may have a limited order allotment and may not be available once the allotment has been taken. If the allotment for a particular date is already consumed, you will not be able to choose the date.</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(6)}} divider>
                    <Typography className={classes.question} variant="h6">Payment options</Typography>
                    {questions[6] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[6]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>LesliesExpress.ph offers the following payment options: Credit Card, Paymaya, Bank Deposit or Online Transfer, GCash, and Cash On Delivery (COD). COD is not available for Airport to Airport deliveries.</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(7)}} divider>
                    <Typography className={classes.question} variant="h6">COD orders</Typography>
                    {questions[7] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[7]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>COD orders are not immediately confirmed orders. After ordering via COD, a LesliesExpress.ph personnel will be contacting you on your mobile number to confirm your order. Confirmed COD orders will receive a confirmation text, only then will a COD order be confirmed.</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(8)}} divider>
                    <Typography className={classes.question} variant="h6">Bank Deposit/GCash payments</Typography>
                    {questions[8] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[8]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>When you order and choose Bank Deposit/GCash as your payment method, your orders are not confirmed until you have uploaded a proof of payment. You can update a proof of payment on the webapp. Just go to the Orders page and find the particular order you want to update. Click on “Update Payment” and upload the proof of payment. A payment and order confirmation will be sent to you after your payment is confirmed. You need to upload the proof of payment at least 6 or 7 hours before the delivery time. If you do not upload a proof of payment by the deadline, your order will be automatically CANCELED. LesliesExpress.ph will not be liable for any canceled orders due to non submission of a proof of payment by the customer.</Typography>
                    </div>
                    </ListItem>
                </Collapse>

                <ListItem className={classes.listQuestion} button onClick={()=>{onClick(9)}} divider>
                    <Typography className={classes.question} variant="h6">Order follow ups and other immediate concerns</Typography>
                    {questions[9] ? <RemoveCircleIcon style={{ color: '#f26522' }}/> : <AddCircleIcon style={{ color: '#f26522' }}/>}
                </ListItem>
                <Collapse in={questions[9]}>
                    <ListItem>
                    <div className={classes.indent}>
                        <Typography>For immediate attention, you can reach us through <a style={{textDecoration: 'none'}} href="tel:+639085286364">+639085286364</a>. </Typography>
                    </div>
                    </ListItem>
                </Collapse>
            </List>
            <br/>
            <Typography variant="body1"><a className={classes.link} target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTLuY9bLnOXnWTpkXnEuY5H31wUNHVPQXenqyjvMurs3zuplsUdWD-IWDSwxSm5FdAztuSmNM0dXfsf/pub?embedded=true">Read our <b>Terms and Conditions</b>.</a></Typography>
            <br/>
            <div className={classes.indent}>
                <Typography variant="body1">Get the latest updates by following our Facebook page: <a className={classes.link} target="_blank" href="https://www.facebook.com/Leslieslechon">Leslie's Lechon</a>.</Typography>
            </div>
                        </Grid>
                    </Grid>
            </div>
        </Fragment>
    )

}

export default withRouter(Faq);
