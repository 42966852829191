import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import PermPhoneMsgSharpIcon from '@material-ui/icons/PermPhoneMsgSharp';
import Hidden from '@material-ui/core/Hidden';
import SidebarNav from './SidenavBar';
import HelpSharpIcon from '@material-ui/icons/HelpSharp';

const useStyles = makeStyles(theme => (
  {
  drawer: {
    width: 240
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
  
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Home',
      href: '/home',
      icon: <HomeSharpIcon />
    },
    {
      title: 'FAQs',
      href: '/faq',
      icon: <HelpSharpIcon />
    },
    {
      title: 'Contact Us',
      href: '/contact',
      icon: <PermPhoneMsgSharpIcon />
    },
    {
      title: 'Login / Signup',
      href: '/login',
      icon: <AccountCircleSharpIcon />
    }
  ];

  return (
    <Fragment>
       <Hidden smUp implementation="css">
          <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant === 'responsive' ? 'temporary' : variant}
          >
            <div
              {...rest}
              className={clsx(classes.root, className)}
            >
              <SidebarNav
                className={classes.nav}
                pages={pages}
              />
            </div>
          </Drawer>
        </Hidden>
        { variant === 'responsive' &&
          <Hidden xsDown implementation="css">
            <Drawer
              anchor="left"
              variant="permanent"
              classes={{ paper: classes.drawer }}
            >
              <div
                {...rest}
                className={clsx(classes.root, className)}
              >
                <Divider className={classes.divider} />
                <SidebarNav
                  className={classes.nav}
                  pages={pages}
                />
              </div>
            </Drawer>
        </Hidden>
        }
    </Fragment>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;