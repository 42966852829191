import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {crud} from '../services/crud';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePagination from '@material-ui/core/TablePagination';
import {format} from 'date-fns';
import {withRouter} from 'react-router-dom';

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    container: {
        padding: '4em 0',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#ffffff'
    }
  });

const AdminOrders = (props) => {
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const classes = useStyles();
    const orders = crud('orders');
    const getOrders = async () => {
        let res = await orders.find({_sort: "updatedAt:DESC", _limit: 1000});
        if(res) {
           let _rows = [];
           res.data.forEach(element => {
            _rows.push({
                id: element._id,
                name: element.user.meta.name,
                batch: element.user.meta.batch,
                serial: element.serial,
                payment: element.meta.payment,
                status: element.status,
                date: element.updatedAt
            });
           });

           setRows(_rows);
        }
    }

    const handleChangePage = (event, page) => {
      setPage(page);
    }

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }

    React.useEffect( () => {
        getOrders();
    },[]);
return(
<div className={classes.container}>
  <Container component={Paper} style={{padding: '1.5em'}}>
    <Table className={classes.table} size="small" aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Name</StyledTableCell>
          <StyledTableCell align="right">Batch</StyledTableCell>
          <StyledTableCell align="right">Serial Number</StyledTableCell>
          <StyledTableCell align="right">Payment Method</StyledTableCell>
          <StyledTableCell align="right">Updated At</StyledTableCell>
          <StyledTableCell align="right">Status</StyledTableCell>
          <StyledTableCell align="right">View</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((row, idx) => (
          <StyledTableRow hover key={idx}>
            <StyledTableCell component="th" scope="row">
              {row.name}
            </StyledTableCell>
            <StyledTableCell align="right">{row.batch}</StyledTableCell>
            <StyledTableCell align="right">{row.serial}</StyledTableCell>
            <StyledTableCell align="right">{row.payment}</StyledTableCell>
            <StyledTableCell align="right">{format(new Date(row.date), "MMM. dd, yyyy hh:mm a")}</StyledTableCell>
            <StyledTableCell align="right">{row.status}</StyledTableCell>
            <StyledTableCell align="right">
                <IconButton href={`/_hidden/orders/${row.id}`}>
                    <VisibilityIcon/>
                </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
  </Container>
  </div>
    )
};

export default withRouter(AdminOrders);