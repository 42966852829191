import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {crud} from '../services/crud';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePagination from '@material-ui/core/TablePagination';
import {format} from 'date-fns';
import {withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    container: {
        padding: '4em 0',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#ffffff'
    },
    message: {
        maxWidth: '300px'
    },
    dialogClose: {
        display: "flex",
        justifyContent: 'flex-end',
        padding: "0.5em 1em"
    }
  });

const AdminContacts = (props) => {
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState({subject: '', email: '', batch: '', message: '', date: new Date});
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const classes = useStyles();
    const contacts = crud('contacts');
    const getOrders = async () => {
        let res = await contacts.find({_sort: "createdAt:DESC", _limit: 1000});
        if(res) {
           let _rows = [];
           res.data.forEach(element => {
            _rows.push({
                email: element.email,
                subject: element.subject,
                batch: element.batch,
                message: element.message,
                date: element.createdAt
            });
           });

           setRows(_rows);
        }
    }

    const handleChangePage = (event, page) => {
      setPage(page);
    }

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }

    const handleDialog = () => (setOpen(!open));

    React.useEffect( () => {
        getOrders();
    },[]);

return(
<div className={classes.container}>
  <Container component={Paper} style={{padding: '1.5em'}}>
    <Table className={classes.table} size="small" aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Email</StyledTableCell>
          <StyledTableCell>Subject</StyledTableCell>
          <StyledTableCell>Batch</StyledTableCell>
          <StyledTableCell>Message</StyledTableCell>
          <StyledTableCell>Date</StyledTableCell>
          <StyledTableCell>Actions</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((row, idx) => (
          <StyledTableRow hover key={idx}>
            <StyledTableCell>{row.email}</StyledTableCell>
            <StyledTableCell>{row.subject}</StyledTableCell>
            <StyledTableCell>{row.batch}</StyledTableCell>
            <StyledTableCell className={classes.message}><Typography noWrap>{row.message}</Typography></StyledTableCell>
            <StyledTableCell>{format(new Date(row.date), "MMM. dd, yyyy hh:mm a")}</StyledTableCell>
            <StyledTableCell>
                <IconButton onClick={()=> {
                    setMsg(row);
                    setOpen(true);
                }}>
                    <VisibilityIcon/>
                </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
  </Container>

  <Dialog onClose={handleDialog} aria-labelledby="simple-dialog-title" open={open}>
  <DialogTitle>{msg.subject}</DialogTitle>
  <Table size="small" aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>{msg.email}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Batch</StyledTableCell>
            <StyledTableCell>{msg.batch}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Message</StyledTableCell>
            <StyledTableCell>{msg.message}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>{format(new Date(msg.date), "MMM. dd, yyyy hh:mm a")}</StyledTableCell>
          </StyledTableRow>
      </TableBody>
   </Table>
   <div className={classes.dialogClose}>
        <Button onClick={handleDialog}>Close</Button>
    </div>
    </Dialog>
  </div>
    )
};

export default withRouter(AdminContacts);