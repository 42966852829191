import React from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Home from '../pages/Home';
import Login from '../pages/Login';
import PostLogin from '../pages/PostLogin';
import Contact from '../pages/Contact';
import Order from '../pages/Order';
import DefaultLayout from "../layouts/default/";
import AuthenticatedLayout from "../layouts/authenticated/";
import Register from '../pages/Register';
import Logout from '../pages/Logout';
import Payment from '../pages/Payment';
import BankDeposit from '../pages/BankDeposit';
import FinalRegister from '../pages/FinalRegister';
import Orders from '../pages/Orders';
import { AuthContext } from '../auth/AuthContext';
import UpdateOrder from '../pages/UpdateOrder';
import ContactDone from '../pages/ContactDone';
import OrderComplete from '../pages/OrderComplete';
import OrderFailed from '../pages/OrderFailed';
import BankConfirm from '../pages/BankConfirm';
import AdminOrders from '../pages/AdminOrders';
import AdminLayout from '../layouts/admin/';
import AdminOrder from '../pages/AdminOrder';
import AdminLogin from '../pages/LoginAdmin';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import ForgotPassword from '../pages/ForgotPassword';
import AdminContacts from '../pages/AdminContacts';
import Faq from '../pages/FAQ';
import AdminSettings from '../pages/AdminSettings';
import Address from '../pages/Address';
import OrderHistory from "../pages/OrderHistory";
import NotFound from "../pages/404";

export default function Routes() {
  const auth = React.useContext(AuthContext);

  const checkAuth = () => {
    return Object.keys(auth.user).length > 0;
  }

  const isAuthenticated = checkAuth();

  const _routes = [
    {
      path: "/home",
      comp: <Home />,
      isAuth: false,
      isInverted: true,
      full: true
    },
    {
      path: "/login",
      comp: <Login />,
      isAuth: false,
      full: true
    },
    {
      path: "/post-login",
      comp: <PostLogin />,
      isAuth: true,
      full: true
    },
    {
      path: "/register",
      comp: <Register />,
      isAuth: false
    },
    {
      path: "/register/complete",
      comp: <FinalRegister />,
      isAuth: true
    },
    {
      path: "/contact/confirm",
      comp: <ContactDone />,
      isAuth: false
    },
    {
      path: "/contact",
      comp: <Contact />,
      isAuth: false,
      isInverted: true
    },
    {
      path: "/orders/:id",
      comp: <UpdateOrder />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/complete/deposit",
      comp: <BankDeposit />,
      isAuth: false,
      isInverted: true
    },
    {
      path: "/orders",
      comp: <Orders />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/history",
      comp: <OrderHistory />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/history/:id",
      comp: <UpdateOrder />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/order/complete",
      comp: <OrderComplete />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/order/failed",
      comp: <OrderFailed />,
      isAuth: false,
      isInverted: true
    },
    {
      path: "/address",
      comp: <Address />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/order",
      comp: <Order />,
      isAuth: false,
      isInverted: true
    },
    ,
    {
      path: "/logout",
      comp: <Logout />,
      isAuth: false
    },
    {
      path: "/payment",
      comp: <Payment />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/bank/confirm",
      comp: <BankConfirm />,
      isAuth: true,
      isInverted: true
    },
    {
      path: "/forgot/password",
      comp: <ForgotPassword />,
      isAuth: false
    },
    {
      path: "/faq",
      comp: <Faq />,
      isAuth: false,
      isInverted: true
    }
  ];

  const paths = _routes.map((x, idx) => {
    return (isAuthenticated ?
      <Route exact path={x.path} key={idx}>
        <AuthenticatedLayout inverted={x.isInverted || false} full={x.full || false}>
          {x.comp}
        </AuthenticatedLayout>
      </Route> :
      !x.isAuth ?
        <Route exact path={x.path} key={idx}>
          <DefaultLayout inverted={x.isInverted || false} full={x.full || false}>
            {x.comp}
          </DefaultLayout>
        </Route>
        :
        <Redirect exact from={x.path} to="/login" key={idx} />
    )
  });

  return (
    <Switch>
      <Redirect exact from="/" to="home" key="home" />
      <Redirect exact from="/_hidden" to="_hidden/orders" key="hidden-orders-redirect" />
      {paths}
      {isAuthenticated ?
        <Route exact path="/_hidden/orders/:id" key="hidden-orders">
          <AdminLayout>
            <AdminOrder />
          </AdminLayout>
        </Route> :
        <Redirect exact from="/_hidden/orders/:id" to="login" key="hidden-orders" />
      }
      {isAuthenticated ?
        <Route exact path="/_hidden/orders" key="hidden-order">
          <AdminLayout>
            <AdminOrders />
          </AdminLayout>
        </Route> :
        <Redirect exact from="/_hidden/orders" to="login" key="hidden-orders" />
      }

      {isAuthenticated ?
        <Route exact path="/_hidden/contacts" key="hidden-contacts">
          <AdminLayout>
            <AdminContacts />
          </AdminLayout>
        </Route> :
        <Redirect exact from="/_hidden/contacts" to="login" key="hidden-contacts" />
      }

      {isAuthenticated ?
        <Route exact path="/_hidden/settings" key="hidden-settings">
          <AdminLayout>
            <AdminSettings />
          </AdminLayout>
        </Route> :
        <Redirect exact from="/_hidden/settings" to="login" key="hidden-settings" />
      }

      <Route exact path="/_hidden/login" key="hidden-login">
        <AdminLayout>
          <AdminLogin />
        </AdminLayout>
      </Route>

      <Route exact path="/privacy-policy" key="privacy-policy">
        <PrivacyPolicy />
      </Route>
      
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}
