import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {StateHelper} from 'react-form-plus';
import {withRouter} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import grey from "@material-ui/core/colors/grey";
import leslie from '../assets/leslie-app-inverted.png';
import gcash from '../assets/gcash.png';
import Button from '@material-ui/core/Button';
import {format, addDays} from 'date-fns';
import Grid from '@material-ui/core/Grid';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",     
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
              },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
              },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '17px',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
        },
        descriptionText: {
            margin: 0,
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        // formControl: {
        //     margin: "1em 0",
        //     background: theme.palette.common.white
        //   },
        descriptionCont: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: theme.spacing(2),
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
             }
        },
        toggleButtons: {
            display: 'flex',
            marginBottom: '5px'
        },
        toggleButton: {
            flex: 1,
            textAlign: 'center',
            padding: '10px',
            '&:hover': {
                cursor: 'pointer',
            },
            '&:hover .MuiTypography-root': {
                color: '#f26522'
            },
            '&:first-child': {
                borderRadius: '8px 0 0 8px',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:last-child': {
                borderRadius: ' 0 8px 8px 0',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderRight: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:not(:first-child):not(:last-child)': {
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:first-child:hover': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&:first-child:hover + &': {
                borderLeft: 'none'
            },
            '&:not(:first-child):not(:last-child):hover': {
                border: '2px solid #f26522',
            },
            '&:not(:first-child):not(:last-child):hover + &': {
                borderLeft: 'none'
            },
            '&:last-child:hover': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            }
        }
    }));

const BankDeposit = props => {
    const classes = useStyles();
    const _date = addDays(new Date(), 5);

    const checkout = () => {
        props.history.push("/home");
    }


    return (
        
        <div className={classes.orderContainer}>
            
          <div className={classes.grad1}>
                <Container>
                    <div className={classes.watchImgCont}>
                            <img className={classes.watchImg} src={leslie}/>
                            
                            <Typography variant="h5" style={{color: '#ffffff', maxWidth: '320px'}}>
                                <span style={{fontWeight: '800'}}>Leslie's Lechon</span> continues the legacy of the "Mother of the Inasal Industry in Talisay".
                            </Typography>
                        </div>
                    </Container>
                </div>
                <Container maxWidth="sm" style={{padding: '10px 0', marginTop: "-200px"}}>
                    <div style={{padding: '40px 50px', borderRadius: '8px', margin: '20px 12px', backgroundColor: '#ffffff', boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"}}>
                        <Grid container>
                            <Grid item>
                                <Typography>
                                    <div className={classes.propertyText}>
                                        Your order has been updated. We will send you a confirmation once your payment is confirmed.
                                    </div>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                <div style={{width: '100%', padding: '10px 0 30px'}}>
                    <Button style={{margin: 'auto', display: 'block'}} color="primary" variant="contained" size="large" onClick={checkout}>Done</Button>
                </div>
            </Container>
        </div>
    )

}

export default withRouter(BankDeposit);
