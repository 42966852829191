import React, {useState, useEffect} from "react";
import {crud} from '../services/crud';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import errorIcon from '../assets/error.svg';

const SettingsContext = React.createContext();

const SettingsProvider = (props) => {
    const ColorCircularProgress = withStyles({
        root: {
          color: '#fff',
        },
      })(CircularProgress);
    const [settings, setSettings] = useState({});

    const [state, setState] = useState(0);

    let loadingTimeout = null

    const getSettings = async () => {
      loadingTimeout = setTimeout(() => {
        setState(3);
      }, 3000);
      const s = crud('settings/all');
      const res = await s.find({});
      if(res) {
          if(res.data.length > 0) {
            if(loadingTimeout) {
              clearTimeout(loadingTimeout);
            }
            let _res = {};
            res.data.forEach(s => {
                _res[s.key] = s.data;
            });
            setSettings(_res);
            setState(1);
          }
      }
    }

    useEffect(()=>{
      getSettings();
      return () => {
        if(loadingTimeout) {
          clearTimeout(loadingTimeout);
        }
      }
    },[]);

    return (
        <SettingsContext.Provider value={{settings: settings, setSettings: setSettings}}>
           {
             state == 0 ?
              <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f26522'}}>
                  <ColorCircularProgress/>
              </div>
             : state == 1 ?
              props.children
            :
            <div style={{width: '100%', height: '100vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", background: '#f26522'}}>
               <img style={{maxHeight: '200px', margin: '30px auto', display: 'block'}} src={errorIcon}/>
               <Typography variant="h4" style={{color: '#ffff'}}>Something went wrong.</Typography>
               <Typography variant="subtitle1" style={{color: '#ffff'}}>Please try again later.</Typography>
               <br/>
                <Typography style={{color: '#ffffff'}} variant='subtitle2'>If this error persists please contact us through<br/><a style={{textDecoration: 'none', color: '#ffffff', fontWeight: 'bold'}} href="tel:+639085286364">+639085286364</a> or <a style={{textDecoration: 'none', color: '#ffffff', fontWeight: 'bold'}} target="_blank" href="https://www.facebook.com/Leslieslechon">fb.com/Leslieslechon</a>.</Typography>
            </div>
           }
        </SettingsContext.Provider>
    )
}

export {SettingsContext, SettingsProvider};