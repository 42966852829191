import React, {useState} from "react";
const OrdersContext = React.createContext();

const OrdersProvider = (props) => {
    const [orders, setOrders] = useState(0);
    const putOrders = (_orders) => {
          setOrders(_orders);
    }
    return (
        <OrdersContext.Provider value={{orders: orders, setOrders: putOrders}}>
            {props.children}
        </OrdersContext.Provider>
    )
}

export {OrdersContext, OrdersProvider};