import React, {Fragment, useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {StateHelper} from 'react-form-plus';
import {withRouter} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import grey from "@material-ui/core/colors/grey";
import leslie from '../assets/leslie-app-inverted.png';
import gcash from '../assets/gcash.png';
import Button from '@material-ui/core/Button';
import {format, addDays} from 'date-fns';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import {crud} from '../services/crud';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",     
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
              },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
              },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '17px',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
        },
        descriptionText: {
            margin: 0,
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        // formControl: {
        //     margin: "1em 0",
        //     background: theme.palette.common.white
        //   },
        descriptionCont: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: theme.spacing(2),
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
             }
        },
        toggleButtons: {
            display: 'flex',
            marginBottom: '5px'
        },
        toggleButton: {
            flex: 1,
            textAlign: 'center',
            padding: '10px',
            '&:hover': {
                cursor: 'pointer',
            },
            '&:hover .MuiTypography-root': {
                color: '#f26522'
            },
            '&:first-child': {
                borderRadius: '8px 0 0 8px',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:last-child': {
                borderRadius: ' 0 8px 8px 0',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderRight: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:not(:first-child):not(:last-child)': {
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:first-child:hover': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&:first-child:hover + &': {
                borderLeft: 'none'
            },
            '&:not(:first-child):not(:last-child):hover': {
                border: '2px solid #f26522',
            },
            '&:not(:first-child):not(:last-child):hover + &': {
                borderLeft: 'none'
            },
            '&:last-child:hover': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            }
        }
    }));

const BankDeposit = props => {
    const {id} = queryString.parse(props.location.search);
    const classes = useStyles();
    const _date = addDays(new Date(), 5);
    const [order, setOrder] = useState(null);

    const getOrder = async () => {
        if(id == undefined) {
            return;
        }
        const _order = crud('orders');
        let res = await _order.findOne(id);
        if(res) {
            if(res.data) {
                setOrder({...res.data});
            }
        }
    };

    const checkout = () => {
        props.history.push("/home");
    }

    const go = () => {
        props.history.push(`/orders/${order.id}`);
    }

    useEffect(()=>{
        getOrder();
    }, []);


    return (
        
        <div>
            <div className="content-paper">
                <Grid container>
                    <Grid item >
                        <div>
                            <p className={classes.propertyText}><Typography>You can transfer payment to the following accounts:</Typography></p>
                            <br/>          
                            <p className={classes.propertyText}><Typography>BANK NAME:	<b>East West Bank</b></Typography></p>
                            <p className={classes.propertyText}><Typography>ACCOUNT NAME: <b>Bitesize Digital Group, Inc.</b></Typography></p>
                            <p className={classes.propertyText}><Typography>ACCOUNT NO.: <b>200037027609</b></Typography></p>
                            <br/>
                            <p className={classes.propertyText}><Typography>BANK NAME: <b>Chinabank</b></Typography></p>
                            <p className={classes.propertyText}><Typography>ACCOUNT NAME: <b>Bitesize Digital Group, Inc.</b></Typography></p>
                            <p className={classes.propertyText}><Typography>ACCOUNT NO.: <b>1280-00-00827-8</b></Typography></p>
                            <br/>
                            <img src={gcash} />
                        </div>
                    </Grid>
                </Grid>
                { order &&
                    <Fragment>
                        <br/>
                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{width: '100%'}}>
                                    <Typography style={{textAlign: 'center', fontWeight: 500}}>
                                    Already completed the payment? You may send your payment details here:
                                    </Typography>
                                    <br/>
                                    <Button variant="contained" style={{display: 'block', margin: 'auto'}} onClick={go}>View Order</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Fragment>
                }
            </div>
            <div style={{width: '100%', padding: '10px 0 30px'}}>
                <Button style={{margin: 'auto', display: 'block'}} color="primary" variant="contained" size="large" onClick={checkout}>Done</Button>
            </div>
        </div>
    )

}

export default withRouter(BankDeposit);
