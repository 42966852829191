import React, {useState} from "react";
import cache from '../services/cache.js';
const OrderContext = React.createContext();

const OrderProvider = (props) => {
    const ord = cache.get('order', {
        items: []
    });
    const [order, setOrder] = useState({...ord});
    const putOrder = (_order) => {
        cache.put('order', _order, {
            persist: true
          });
          setOrder(_order);
    }
    return (
        <OrderContext.Provider value={{order: order, setOrder: putOrder}}>
            {props.children}
        </OrderContext.Provider>
    )
}

export {OrderContext, OrderProvider};