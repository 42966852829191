import React, { Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { StateHelper } from 'react-form-plus';
import { OrderContext } from '../store/OrderContext';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import grey from "@material-ui/core/colors/grey";
import { SettingsContext } from '../store/SettingsContext';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { List, ListItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import red from '@material-ui/core/colors/red';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import clsx from 'clsx';
import { AuthContext } from '../auth/AuthContext';

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
            },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
            },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            margin: '0 8px',
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '25px',
                fontWeight: '700',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        innerInput: {
            fontSize: '25px',
            fontWeight: '700',
            textAlign: 'center',
            borderBottom: '2px solid #cccccc'
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
        },
        descriptionText: {
            fontSize: '1.5em',
            margin: theme.spacing(2),
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        formControl: {
            margin: "1em 0",
            background: theme.palette.common.white
        },
        descriptionCont: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
            }
        },
        removeButton: {
            // borderRadius: '50%',
            // background: ,
            // display: 'flex',
            position: 'absolute',
            // height: '40px',
            // width: '40px',
            // justifyContent: 'center',
            // alignItems: 'center',
            top: '10px',
            right: '10px',
            '&:hover': {
                background: red[400],
            },
            '&:hover .MuiSvgIcon-root': {
                color: '#fff',
            },
        },
        innerPaper: {
            position: 'relative',
            padding: '30px 50px',
            borderRadius: '8px',
            margin: '20px 12px',
            backgroundColor: '#ffffff',
            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            [theme.breakpoints.down('md')]: {
                padding: '20px 30px',
            },
        },
        toggleButtons: {
            display: 'flex',
            margin: ' 0 15px 5px 15px'
        },
        buttonItem: {
            padding: '30px',
            borderRadius: '8px',
            minWidth: '300px',
            backgroundColor: '#ffffff',
            border: '2px solid rgba(0,0,0,0.3)',
            '&:hover': {
                border: '2px solid #f26522'
            }
        },
        toggleButton: {
            flex: 1,
            textAlign: 'center',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                cursor: 'pointer',
            },
            '&:hover .MuiTypography-root': {
                color: '#f26522'
            },
            '&:first-child': {
                borderRadius: '8px 0 0 8px',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:last-child': {
                borderRadius: ' 0 8px 8px 0',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderRight: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:not(:first-child):not(:last-child)': {
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:first-child:hover': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&:first-child:hover + &': {
                borderLeft: 'none'
            },
            '&:not(:first-child):not(:last-child):hover': {
                border: '2px solid #f26522',
            },
            '&:not(:first-child):not(:last-child):hover + &': {
                borderLeft: 'none'
            },
            '&:last-child:hover': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            },
            '&.active .MuiTypography-root': {
                color: '#f26522'
            },
            '&.active:first-child': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&.active:first-child + &': {
                borderLeft: 'none'
            },
            '&.active:not(:first-child):not(:last-child)': {
                border: '2px solid #f26522',
            },
            '&.active:not(:first-child):not(:last-child) + &': {
                borderLeft: 'none'
            },
            '&.active:last-child': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            }
        },
        whiteButton: {
            borderColor: "#fff",
            color: "#fff",
        }
    }));

const ColorCircularProgress = withStyles({
    root: {
        color: '#fff',
    },
})(CircularProgress);

const Order = props => {
    const classes = useStyles();
    const ord = React.useContext(OrderContext);
    const [state, setState] = React.useState([...ord.order.items]);
    const { settings } = React.useContext(SettingsContext);
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(false);
    const { user } = React.useContext(AuthContext);

    const handleClose = (value) => {
        setOpen(false);
        setSelected(false);
    };

    const addOrder = (id) => {
        setState(
            state.map((item) => {
                if (item.id === id) {
                    return { ...item, qty: item.qty + 1 };
                } else {
                    return item;
                }
            })
        )
    }

    const removeOrder = (id) => {
        setState(
            state.map((item) => {
                if (item.id === id) {
                    if (item.qty == 1) {
                        return item;
                    }
                    return { ...item, qty: item.qty - 1 };
                } else {
                    return item;
                }
            })
        )
    }

    const addSize = (size, type) => {
        const s = state;
        s.push(
            {
                id: (state.length > 0 ? state[state.length - 1].id : 0) + 1,
                size: size,
                type: type,
                flavor: 'original',
                qty: 1
            }
        );
        ord.setOrder({ ...ord.order, items: s, meta: { subTotal: total() }, total: total() });
        setState([...s]);
        handleClose();
    }

    const handleItemChange = (id, field, event, number = false) => {
        setState(
            state.map((item) => {
                if (item.id === id) {
                    return { ...item, [field]: !number ? event.target.value : parseInt(event.target.value) };
                } else {
                    return item;
                }
            })
        )
    };

    const total = () => {
        let t = 0;
        state.forEach(item => {
            const subTotal = parseInt(settings.prices[item.type][item.size].price[item.flavor]) * item.qty;
            t += subTotal;
        });
        return t;
    }


    const checkout = () => {
        ord.setOrder({ ...ord.order, items: state, meta: { subTotal: total() }, total: total() });
        if (Object.keys(user).length > 0) {
            return props.history.push('/address');
        }

        return props.history.push('/login?redirect');
    }

    const removeItem = (id) => {
        const _order = state.filter(item => item.id !== id);
        if (_order.length > 0) {
            ord.setOrder({ ...ord.order, items: _order, meta: { subTotal: total() }, total: total() });
        } else {
            ord.setOrder({ items: [] });
        }
        setState([..._order]);
    }

    const handleSelect = (val) => {
        setSelected(val);
    }

    React.useEffect(() => {
        if (ord.order.items.length <= 0) {
            setOpen(true);
        }
    }, []);

    return (
        <div>
            {
                state.map((el, idx) => (
                    <div key={idx} className={classes.innerPaper}>

                        <IconButton className={classes.removeButton} onClick={() => { removeItem(el.id) }}>
                            <DeleteIcon />
                        </IconButton>
                        <Typography color="primary" style={{ fontWeight: '800' }} variant="h4">{el.type == 'whole' ? 'Whole Lechon' : 'Lechon Belly'}</Typography>
                        <Divider style={{ backgroundColor: "#d1d1d1", margin: '5px 0 10px 0' }} />
                        <Grid container>
                            <Grid item xs={5} sm={5}>
                                <div style={{ padding: '0.5em 0' }}>
                                    <Typography variant="subtitle2">
                                        <p className={classes.propertyText}>Size</p>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <p className={classes.infoText}>{el.size.toUpperCase()}</p>
                                    </Typography>
                                </div>
                                <div style={{ padding: '0.5em 0' }}>
                                    <Typography variant="subtitle2">
                                        <p className={classes.propertyText}>Price</p>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <p className={classes.infoText}>&#8369;{parseFloat(settings.prices[el.type][el.size].price[el.flavor]).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={7} sm={7}>
                                <div style={{ padding: '0.5em 0' }}>
                                    <Typography variant="subtitle2">
                                        <p className={classes.propertyText}>Weight</p>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <p className={classes.infoText}>{settings.prices[el.type][el.size].weight} KG</p>
                                    </Typography>
                                </div>
                                <div style={{ padding: '0.5em 0' }}>
                                    <Typography variant="subtitle2">
                                        <p className={classes.propertyText}>Good for</p>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <p className={classes.infoText}>{settings.prices[el.type][el.size].pax} PAX</p>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5} sm={5}>
                                <div style={{ padding: '0.5em 0' }}>
                                    <Typography variant="subtitle2">
                                        <p className={classes.propertyText}>Flavor</p>
                                    </Typography>
                                </div>
                                <RadioGroup aria-label="flavor" row name="flavor" value={el.flavor} onChange={evt => { handleItemChange(el.id, 'flavor', evt) }}>
                                    <FormControlLabel value="original" control={<Radio color="primary" />} label="Original" />
                                    <FormControlLabel value="spicy" control={<Radio color="primary" />} label="Spicy" />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={7} sm={7}>
                                <div style={{ padding: '0.5em 0' }}>
                                    <Typography variant="subtitle2">
                                        <p className={classes.propertyText}>Qty.</p>
                                    </Typography>
                                </div>
                                <IconButton aria-label="remove" onClick={() => { removeOrder(el.id) }}>
                                    <RemoveCircleIcon color="primary" />
                                </IconButton>
                                <TextField
                                    type="number"
                                    style={{ maxWidth: '50px' }}
                                    className={classes.input}
                                    InputProps={{
                                        classes: {
                                            input: classes.innerInput,
                                        },
                                    }}
                                    value={el.qty}
                                    onChange={evt => { handleItemChange(el.id, 'qty', evt, true) }}
                                />
                                <IconButton aria-label="add" onClick={() => { addOrder(el.id) }}>
                                    <AddCircleIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                ))
            }
            <div style={{ width: '100%', padding: '20px 0 20px' }}>
                <Button style={{ margin: 'auto', display: 'block' }} className={state.length <= 0 ? classes.whiteButton : ''} variant="outlined" size="large" onClick={() => { setOpen(true) }}>Add Size/Flavor</Button>
            </div>

            {state.length > 0 &&
                <Fragment>
                    <div style={{ width: '100%', padding: '20px 0 10px' }}>
                        <Typography style={{ textAlign: 'right', margin: '5px 12px' }} variant="h4">Total: &#8369;{total().toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                    </div>

                    <div style={{ width: '100%', padding: '10px 0 30px' }}>
                        <Button style={{ margin: 'auto', display: 'block' }} color="primary" variant="contained" size="large" onClick={checkout}>Checkout</Button>
                    </div>
                </Fragment>}
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle disableTypography><Typography variant="h6">Select Size</Typography><IconButton onClick={handleClose} style={{ position: 'absolute', top: '4px', right: '8px' }}><CancelSharpIcon /></IconButton></DialogTitle>
                <div className={classes.toggleButtons}>
                    <div className={!selected ? clsx(classes.toggleButton, "active") : classes.toggleButton} onClick={() => { handleSelect(false) }}>
                        <Typography variant="h6">Whole Lechon</Typography>
                    </div>
                    <div className={selected ? clsx(classes.toggleButton, "active") : classes.toggleButton} onClick={() => { handleSelect(true) }}>
                        <Typography variant="h6">Lechon Belly</Typography>
                    </div>
                </div>
                
                {settings.availability[!selected ? 'whole' : 'belly'] ?
                <List style={{ padding: '0', minHeight: '590px', minWidth: '330px'}}>
                    {
                        Object.keys(settings.prices[!selected ? 'whole' : 'belly']).map((v, idx) => (
                            <Fragment key={idx}>
                                        <Divider />
                                        <ListItem button key={idx} onClick={() => { addSize(v, !selected ? 'whole' : 'belly') }}>
                                            <div className={classes.buttonItem}>
                                                <Grid container>
                                                    <Grid item xs={5} sm={6}>
                                                        <div style={{ padding: '0.5em 0' }}>
                                                            <Typography variant="subtitle2">
                                                                <p className={classes.propertyText}>Size</p>
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                <p className={classes.infoText}>{v.toUpperCase()}</p>
                                                            </Typography>
                                                        </div>
                                                        <div style={{ padding: '0.5em 0' }}>
                                                            <Typography variant="subtitle2">
                                                                <p className={classes.propertyText}>Price</p>
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                <p className={classes.infoText}>&#8369;{parseFloat(settings.prices[!selected ? 'whole' : 'belly'][v].price["original"]).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={7} sm={6}>
                                                        <div style={{ padding: '0.5em 0' }}>
                                                            <Typography variant="subtitle2">
                                                                <p className={classes.propertyText}>Weight</p>
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                <p className={classes.infoText}>{settings.prices[!selected ? 'whole' : 'belly'][v].weight} KG</p>
                                                            </Typography>
                                                        </div>
                                                        <div style={{ padding: '0.5em 0' }}>
                                                            <Typography variant="subtitle2">
                                                                <p className={classes.propertyText}>Good for</p>
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                <p className={classes.infoText}>{settings.prices[!selected ? 'whole' : 'belly'][v].pax} PAX</p>
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </ListItem>
                            </Fragment>
                        ))
                    }
                </List>
                :
                <div style={{ display: 'flex', padding: '0', minHeight: '590px', minWidth: '330px', justifyContent: 'center', alignItems: 'center'}}>
                    <b style={{marginRight: '3px'}}>{!selected ? 'Whole Lechon' : 'Lechon Belly'}</b> is currently unavailable.
                </div>}
            </Dialog>
        </div>
    )

}

export default withRouter(Order);
