import React, { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Toolbar from '@material-ui/core/Toolbar';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './components/sidebar';
import { AuthContext } from '../../auth/AuthContext';
import { OrdersContext } from '../../store/OrdersContext';
import { withRouter } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Content from './components/Content';
import Badge from '@material-ui/core/Badge';
import config from "../../config/config";
import $http from '../../services/http';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1
  },
  badge: {
    "& .MuiBadge-badge": {
      backgroundColor: grey[50],
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
      // backgroundColor: "pink"
    }
  }
}));

function AuthenticatedLayout({ children, history, inverted, location, full = false }) {
  const classes = useStyles();
  const { user } = React.useContext(AuthContext);
  const { orders, setOrders } = React.useContext(OrdersContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const checkInfo = () => {
    if(location.search.includes('redirect')) {
      return;
    }

    if (user.meta) {
      if (user.meta.firstName === undefined || user.meta.phone === undefined || user.meta.lastName === undefined) {
        history.replace('/register/complete');
      }
    }
  }

  const getOrders = async () => {
    if(location.pathname == '/logout') {
      setOrders(0);
      console.log('remove orders');
      return;
    }
    const url = config.app.server.url + `/pendingorders?s:user.username=${user.username}&or:status=confirmed&or:status=rejected&or:status=pending&_sort=createdAt:DESC`;
    let res = await $http.get(url);
    if (res) {
      if (res.data) {
        setOrders(res.data);
      }
    }

    console.log('adding orders');

  };

  React.useEffect(() => history.listen(() => {
    // do something on route change
    // for my example, close a drawer
    getOrders();
  }), [])

  React.useEffect(() => {
    checkInfo();
    getOrders();
  }, []);

  const appDrawerItems = [
    [
      { icon: <FormatListNumberedIcon />, label: "My Orders" },
      { icon: <AccountBalanceIcon />, label: "Bank Details" },
      { icon: <FormatListNumberedIcon />, label: "Contact Us" }
    ],
    [
      { icon: <FormatListNumberedIcon />, label: "Logout" }
    ]
  ]

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {
        appDrawerItems.map((el, idx) => {
          return (
            <Fragment key={idx}>
              <Divider />
              <List>
                {el.map((item, index) => (
                  <ListItem button key={item.label}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
              </List>
            </Fragment>
          )
        })
      }
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{ background: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            color={inverted ? "inherit" : "primary"}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            {orders > 0 ?
              <Badge color="secondary" className={classes.badge} variant="dot">
                <MenuIcon className={classes.MenuIcon} />
              </Badge>
              :
              <MenuIcon className={classes.MenuIcon} />
            }
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Sidebar open={mobileOpen} variant="temporary" onClose={handleDrawerToggle} />
      </nav>
      <main id="main" className={classes.content}>
        {
          full ?
            children
            :
            <Content>
              {children}
            </Content>
        }
      </main>
    </div>
  );
}

export default withRouter(AuthenticatedLayout);