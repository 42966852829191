import firebase from "firebase/app";
import "firebase/auth";
import config from '../config/config';
import http from '../services/http';

firebase.initializeApp(config.firebase);

async function whoAmI() {
  var me = await http
    .get(config.app.server.url + "/users/me")
    .catch(() => {
      return Promise.resolve({
        data: null
      });
    });
    
  return me.data;
}

const _firebase = {
  firebase: firebase,
  whoAmI: whoAmI
};
export default _firebase;

