import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { StateHelper } from 'react-form-plus';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../auth/AuthContext';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import http from '../services/http';
import config from '../config/config';
import Typography from '@material-ui/core/Typography';
import firebase from './firebase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormHelperText, MenuItem } from '@material-ui/core';
import addressList from '../assets/address.json';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import queryString from 'query-string';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
    input: {
        // margin: '1em 0'
    },
    formControl: {
        backgroundColor: theme.palette.common.white,
        margin: '1em 0'
      },
     loginButton: {
        marginLeft: 'auto',
        display: 'block'
      },
      button: {
        margin: '0.5em 0'
      },
      divider: {
          margin: '2em 0',
          backgroundColor: theme.palette.common.white
      },
      icon: {
          maxHeight: '1.5em',
          margin: '0 1em'
      },
      loader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      error: {
        backgroundColor: theme.palette.error.dark,
      },
      formControl: {
        backgroundColor: theme.palette.common.white,
        margin: '1em 0'
      },
      checkbox: {
        color: '#ffffff',
        fontWeight: 500,
        '&.Mui-checked': {
          color: '#ffffff'
        }
      },
      link: {
        textDecoration: "none",
        color: '#f26522',
        fontWeight: 900,
        '&:hover': {
          textDecoration: "underline",
        }
      }
  }));

  const years = [];

  for(var i = 2019; i >= 1970;i-=1) {
    years.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
  }

const FinalRegisterForm = props => {
    const parsed = queryString.parse(props.location.search);
    const {user, setUser} = React.useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    fs.useState(state, setState);

    const submit = async () => {
      setIsLoading(true);
      let url = config.app.server.url + `/auth-otp/me/${user.id}`;
      const doc = {...state.meta};
      doc.agreed = new Date();
       await http.put(url, doc).then(res=> {
         setUser({...res.data, token: user.token});
         if(parsed.redirect !== undefined) {
            return props.history.push('/order');
         }
         props.history.push('/');
        }).catch(err => {
          console.log(err);
        });
    }

    const checkSubmit = () => {
      let err = [];

      if(!checked) {
        err.push('agree');
      }

      Object.keys(state.meta).forEach((key) => {
        if(state.meta[key] === '') {
          err.push(key);
        }
      });

      setErrors(err);

      if(err.length === 0) {
        // setOpen(true);
        submit();
      }
      
    }

    const agree = () => {
      handleClose();
      submit();
    }

    React.useEffect(()=> {
      if(user.token) {
        setState({
          ...user,
          meta: {
            firstName: '',
            lastName: '',
            ...user.meta,
            phone: user.username,
          }
        });
      }
    },[]);

    const handleClose = () => {
      setOpen(false);
    };

    const handleToggle = event => {
      setChecked(event.target.checked);
    }

    return (
           <Fragment>
            {state.meta &&
                <Fragment>
                  <TextField type="text" id="firstname" className={classes.input} label="First Name" 
                    error = {errors.includes('firstName') ? true : false}
                    helperText = {errors.includes('firstName') ? 'This field is required.' : null}
                    {...fs.model('meta.firstName')} variant="filled" fullWidth margin="dense"/>
                  <TextField type="text" id="name" className={classes.input} label="Last Name" 
                    error = {errors.includes('lastName') ? true : false}
                    helperText = {errors.includes('lastName') ? 'This field is required.' : null}
                    {...fs.model('meta.lastName')} variant="filled" fullWidth margin="dense"/>
                  <TextField type="number" id="phone" className={classes.input} label="Mobile Number"
                  error = {errors.includes('phone') ? true : false} 
                  helperText = {errors.includes('phone') ? "Invalid Phone Number" : ''}{...fs.model('meta.phone')}
                  disabled
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                  }}
                  variant="filled" fullWidth margin="dense"/>
                  <FormControl>
                    <FormControlLabel control={<Checkbox name="agree" onChange={handleToggle} value={checked}/>} label={<Typography variant="h6">I have read and accept the <a href="https://docs.google.com/document/d/e/2PACX-1vTLuY9bLnOXnWTpkXnEuY5H31wUNHVPQXenqyjvMurs3zuplsUdWD-IWDSwxSm5FdAztuSmNM0dXfsf/pub?embedded=true" target="_blank" className={classes.link}>Terms & Conditions</a></Typography>} />
                  </FormControl>
                  { errors.includes('agree') &&
                    <FormHelperText error>
                        You must accept the Terms and Conditions to continue.
                    </FormHelperText>
                  }
                  <Button variant="contained" color="primary" className={classes.loginButton} onClick={checkSubmit}>
                      Submit
                  </Button>
                </Fragment>
            }

          <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{"Terms & Conditions"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <iframe width="300" height="300" frameBorder="0" src="https://docs.google.com/document/d/e/2PACX-1vTLuY9bLnOXnWTpkXnEuY5H31wUNHVPQXenqyjvMurs3zuplsUdWD-IWDSwxSm5FdAztuSmNM0dXfsf/pub?embedded=true"></iframe>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={agree} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
    )
}

export default withRouter(FinalRegisterForm);