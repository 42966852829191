import React, {Suspense, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';
import doneIcon from '../assets/done.svg';
import {AuthContext} from '../auth/AuthContext';
import queryString from 'query-string';

const useStyles = makeStyles(theme => (
    {
    container: {
        backgroundColor: '#ffffff',
        height: '100vh', 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        backgroundColor: '#f26522',        
        padding: '25px'
    },
    header: {
        color: grey[100],
        textAlign: 'center',
        margin: '0.5em'
    },
    inner: {
        maxWidth: '500px'
    },
    loader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
        text: {
        textAlign: 'center',
        color: '#ffffff',
        margin: '15px 0',
        fontWeight: '600',
        fontSize: '15px'
    },
    send: {
        maxHeight: '80px',
        margin: '35px auto',
        display: 'block'
    },
  }));


const PostLogin = (props) => {
    const {user} = React.useContext(AuthContext);
    const classes = useStyles();
    const parsed = queryString.parse(props.location.search);
    let otpTimeOut;

    useEffect(()=>{
        otpTimeOut = setTimeout(()=>{
            if(Object.keys(user.meta).length > 0) {
                if(user.meta.firstName && user.meta.lastName && user.meta.address) {
                    if(parsed.redirect !== undefined) {
                        return props.history.push('/order');
                    }
                    props.history.push('/home');
                    return;
                }
            }
            if(parsed.redirect !== undefined) {
                props.history.push('/register/complete?redirect');
                return;
            }
            props.history.push('/register/complete');
        }, 2000);
        return () => {
            clearTimeout(otpTimeOut);
        }
    },[]);

    return(
        <div className={classes.container}>         
            <Container className={classes.inner} maxWidth="sm">
                <Card className={classes.card}>
                    <CardContent>
                        <Suspense fallback={<CircularProgress/>}>
                        <>
                            <Typography className={classes.header} variant="h4">
                                Success
                            </Typography>
                            <img className={classes.send} src={doneIcon}/>
                            <Typography className={classes.text}>Hooray! OTP verified successfully.<br/>Signing you in...</Typography>
                            
                            <span style={{margin: '10px 0', display: 'inline-block'}}></span>

                            <div className={classes.loader}><CircularProgress style={{color: '#ffffff'}}/></div>
                        </>
                        </Suspense>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default withRouter(PostLogin);