import React, {Suspense} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginForm = React.lazy(() => import('../auth/LoginForm'));

const useStyles = makeStyles(theme => (
    {
    container: {
        backgroundColor: '#ffffff',
        height: '100vh', 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        backgroundColor: '#f26522',        
        padding: '25px'
    },
    header: {
        color: grey[100],
        textAlign: 'center',
        margin: '0.5em'
    },
    inner: {
        maxWidth: '500px'
    }
  }));


const Login = () => {
    const classes = useStyles();

    return(
        <div className={classes.container}>         
            <Container className={classes.inner} maxWidth="sm">
                <Card className={classes.card}>
                    <CardContent>
                        <Suspense fallback={<CircularProgress/>}>
                            <LoginForm />
                        </Suspense>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default Login;