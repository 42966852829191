import React, {Fragment} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import config from "../config/config";
import grey from "@material-ui/core/colors/grey";
import {AuthContext} from '../auth/AuthContext';
import $http from '../services/http';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",     
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
              },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
              },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            margin: '0 8px',
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '25px',
                fontWeight: '700',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        innerInput: {
            fontSize: '25px',
            fontWeight: '700',
            textAlign: 'center'
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
        },
        descriptionText: {
            fontSize: '1.5em',
            margin: theme.spacing(2),
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        formControl: {
            margin: "1em 0",
            background: theme.palette.common.white
          },
        descriptionCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
             }
        }
    }));

const OrderHistory = () => {
    const classes = useStyles();
    const {user} = React.useContext(AuthContext);
    const [state, setState] = React.useState(false);

    const getLabel = (type, key) => {
        switch(key) {
            case 'xendit':
                return 'Online Payment'
            case 'bank':
                return 'Bank Deposit'
            case 'maya':
                return 'Maya'
            case 'cc':
                return 'Credit Card'
            case 'cod':
                return `${type == 'cebu' ? 'Cash on Delivery' : 'Cash on Pickup'}`
        }
    }
    

    const getOrders = async () => {
        const url = config.app.server.url + `/orders?s:user.username=${user.username}&or:status=completed&or:status=cancelled&_sort=createdAt:DESC`;
        let res = await $http.get(url);
        if(res) {
            if(res.data) {
                setState(res.data);
            }
        }
        
    };

    React.useEffect(()=> {
        getOrders();
    },[]);

    return (
        
        <div>
           {
               Array.isArray(state) &&
               (
                    state.length > 0 ?
                    state.map((el, idx)=>(
                        <div key={idx} style={{padding: '30px 40px', borderRadius: '8px', margin: '20px 12px', backgroundColor: '#ffffff', boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"}}>
                            <Grid container>
                                <Grid item xs={7} sm={7}>
                                    <div style={{padding: '0.5em 10px'}}>
                                        <Typography variant="subtitle2">
                                            <p className={classes.propertyText}>Ordered At</p>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <p className={classes.infoText}>{format(new Date(el.createdAt), "MMM dd, yyyy hh:mm a")}</p>
                                        </Typography>
                                    </div>
                                    <div style={{padding: '0.5em 10px'}}>
                                        <Typography variant="subtitle2">
                                            <p className={classes.propertyText}>Status</p>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <p className={classes.infoText}>{el.status.charAt(0).toUpperCase() + el.status.substring(1)}</p>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={5} sm={5}>
                                    <div style={{padding: '0.5em 10px'}}>
                                        <Typography variant="subtitle2">
                                            <p className={classes.propertyText}>Payment</p>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <p className={classes.infoText}>{getLabel(el.meta.type, el.meta.payment.method)}</p>
                                        </Typography>
                                    </div>
                                    <div style={{padding: '0.5em 10px'}}>
                                        <Typography variant="subtitle2">
                                            <p className={classes.propertyText}>Total</p>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <p className={classes.infoText}>&#8369;{el.total.toLocaleString()}.00</p>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Button style={{marginTop: '15px'}} href={`/#/history/${el._id}`}>View Order</Button>
                        </div>
                    ))
                    :
                    <Fragment>
                        <Typography variant="h4" style={{color: '#fff', textAlign: 'center', marginTop: '40px'}}>You haven't completed any orders yet.</Typography>
                        <Typography variant="h6" style={{color: '#fff', textAlign: 'center'}}>When you do, they will appear here.</Typography>
                    </Fragment>
               )
           }
        </div>
    )

}

export default OrderHistory;
