import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StateHelper } from 'react-form-plus';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import red from "@material-ui/core/colors/red";
import leslie from '../assets/leslie-app-inverted.png';
import Button from '@material-ui/core/Button';
import { AuthContext } from '../auth/AuthContext';
import { SettingsContext } from '../store/SettingsContext';
import http from '../services/http';
import config from '../config/config';
import { crud } from '../services/crud';
import DateFnsUtils from '@date-io/date-fns';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import { format } from 'date-fns';
import airportList from '../assets/airports.json';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
  {
    grad1: {
      backgroundColor: '#f26522',
      width: '100%',
      display: 'flex',
      padding: '20px 0 170px',
    },
    watchImg: {
      maxWidth: "350px",
      display: 'block',
      marginBottom: '1em',
      [theme.breakpoints.down('md')]: {
        width: '90%'
      },
      alignItems: 'center',
    },
    watchImgCont: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      [theme.breakpoints.down('md')]: {
        // justifyContent: 'center',
      },
      height: '100%',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      padding: '20px',
      marginTop: '20px'
    },
    input: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& .MuiInputAdornment-root .MuiTypography-root': {
        fontSize: '17px',
      },
      '& .MuiInputAdornment-root': {
        marginRight: '4px'
      },
      '& .MuiFormHelperText-contained': {
        margin: '8px 14px'
      },
      '& .MuiInput-underline:before': {
        border: 'none',
      }
    },
    displayFlex: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      flexDirection: 'row',
    },
    width50: {
      width: '50%',
    },
    orderContainer: {
      minHeight: '100vh'
    },
    contentContainer: {
      width: '100%',
      margin: '0 auto',
    },
    propertyText: {
      fontSize: '1em',
      margin: '0',
    },
    descriptionText: {
      margin: 0,
    },
    magisText: {
      fontSize: '18px',
      fontWeight: '500',
      margin: '0',
    },
    infoText: {
      fontWeight: '700',
      fontSize: '18px',
      margin: '0',
    },
    // formControl: {
    //     margin: "1em 0",
    //     background: theme.palette.common.white
    //   },
    descriptionCont: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: theme.spacing(2),
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ffffff',
      padding: '4em 1em',
    },
    input: {
      backgroundColor: theme.palette.common.white,
      margin: '1em 0'
    },
    formControl: {
      backgroundColor: theme.palette.common.white,
      margin: '1em 0'
    },
    loginButton: {
      margin: '10px auto 10px auto',
      display: 'block'
    },
    button: {
      margin: '0.5em 0'
    },
    divider: {
      margin: '2em 0',
      backgroundColor: theme.palette.common.white
    },
    icon: {
      maxHeight: '1.5em',
      margin: '0 1em'
    },
    loader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    imagePicker: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '200px',
      margin: '1em 0',
      cursor: 'pointer'
    },
    imagePickerError: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '200px',
      margin: '1em 0',
      cursor: 'pointer',
      borderRadius: '5px',
      border: '1px solid red'
    },
    selectedImg: {
      maxWidth: '100%'
    },
    inputFile: {
      display: 'none'
    },
    datePicker: {
      margin: '1em 0',
      width: '100%'
    },
    imgOverlay: {
      position: 'absolute',
      background: 'rgba(255,255,255, 0.3)',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    loaderCont: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5em 0'
    },
    list: {
      padding: '0',
      '& .MuiListItemText-root': {
        padding: '0'
      },
      '& .MuiListItemText-root .MuiListItemText-secondary': {
        fontSize: '14px',
        lineHeight: '20px',
        marginTop: '3px'
      }
    }
  }));

const UpdateOrder = props => {
  const { user } = React.useContext(AuthContext);
  const { settings } = React.useContext(SettingsContext);
  const [order, setOrder] = React.useState({ meta: { payment: { method: '' } } });
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  fs.useState(order, setOrder);
  const classes = useStyles();
  const orderId = props.match.params.id;
  const _order = crud('orders');
  let chooser;
  let [img, setImg] = React.useState({});
  let [errs, setErrs] = React.useState([]);

  let snackBarTimeout = null;
  let scrollTimeout = null;

  const getOrder = async () => {
    let res = await _order.findOne(orderId);
    let _ord = {
      ...res.data,
      proof: {
        ...res.data.proof,
        date: res.data.proof.date ? res.data.proof.date : new Date(),
        photo: res.data.proof.photo ? res.data.proof.photo : '',
      }
    }
    if (res.data.proof) {
      if (res.data.proof.photo) {
        setImg({
          url: `${config.app.server.url}${res.data.proof.photo}`
        })
      }
    }
    if (_ord.meta.payment.method == "bank" && _ord.status == 'pending') {
      const scrollingElement = document.getElementById("container");
      if (scrollingElement) {
        scrollTimeout = setTimeout(() => {
          window.scrollTo({ top: scrollingElement.scrollHeight, behavior: 'smooth' });
        }, 200);
      }
    }
    setOrder(_ord);
  };

  React.useEffect(() => {
    if (user.token) {
      getOrder();
    }
  }, [user.token]);

  const choose = () => {
    chooser.click();
  }

  const onChange = (e) => {
    setIsLoading(false);
    if (e.target.files[0]) {
      setImg({
        img: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0])
      });
    }
  }

  const submitOrder = async () => {
    setIsLoading(true);
    let ord = order;
    if (img.img) {
      let fd = new FormData();
      fd.append('files', img.img);
      const url = config.app.server.url + `/upload`;
      let res = await http.post(url, fd);
      if (res.data[0]) {
        const imgUrl = res.data[0].url.replace(/^[a-z]{4}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '$1');
        ord.proof.photo = '/' + imgUrl;
      }
    }
    ord.orderUpdate = true;
    let r = await _order.save(ord);
    if (r.data) {
      setOpen(true);
      snackBarTimeout = setTimeout(() => {
        setOpen(false);
        props.history.push('/orders');
      }, 3000);
    }
  }

  const handleDateChange = (e, model) => {
    setIsLoading(false);
    fs.setState({
      [model]: e.toString()
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const calFee = () => {
    let f = _order.items[0].price * 0.03;
    return Math.ceil((f + 1) / 10) * 10;
  }

  const continuePayment = () => {
    window.location = order.payment.invoice_url;
  }

  const getType = t => {
    switch (t) {
      case 'cebu': return 'Within Cebu'; break;
      case 'airport': return 'Airport to Airport'; break;
      case 'pickup': return 'Pickup'; break;
    }
  }

  const getPayment = p => {
    switch (p) {
      case 'xendit': return 'Online Payment'; break;
      case 'cod': return 'Cash on Delivery'; break;
      case 'cc': return 'Credit Card'; break;
      case 'bank': return 'Bank Deposit/G-Cash'; break;
      case 'maya': return 'Paymaya'; break;
    }
  }

  const getAirport = a => {
    const _airport = airportList.filter((x) => {
      return x.iata_code == a
    });
    if (_airport.length > 0) {
      return `${_airport[0].iata_code} | ${_airport[0].name} - ${_airport[0].city}`;
    }
    return ''
  }

  const checkOrder = () => {
    let err = [];

    if (img.img === undefined && order.proof.photo === '') {
      err.push('photo');
    }

    setErrs(err);

    if (err.length === 0) {
      submitOrder();
    }
  }

  const isDisabled = () => {
    return order.status !== "pending" && order.status !== "rejected";
  };

  useEffect(() => {
    return () => {
      if (snackBarTimeout) {
        clearTimeout(snackBarTimeout);
      }
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    }
  }, []);

  return (

    <div id="container">
      <div className="content-paper">
        {
          order.items &&
          <Fragment>
            <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Order Summary</Typography>
            <Typography className={classes.labels} variant="caption">
              ITEMS
            </Typography>
            {
              order.items.map((item, idx) => (
                <Fragment key={idx}>
                  <Grid container style={{ marginTop: '10px' }}>
                    <Grid item xs={4}>
                      <Typography><b>{item.type == "whole" ? "Whole Lechon" : "Lechon Belly"}</b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {/* <Typography>X{item.qty}</Typography> */}
                    </Grid>
                    <Grid item xs={4}>

                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography style={{ paddingLeft: '10px' }}>{item.size.charAt(0).toUpperCase() + item.size.substring(1)} {item.type == 'belly' && `(${item.size.charAt(1)}KG)`} - {item.flavor == 'spicy' ? "Spicy" : "Original"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>X{item.qty}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{(item.qty * item.price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                    </Grid>
                  </Grid>
                </Fragment>
              ))
            }
            <Divider variant="middle" style={{ backgroundColor: "#d1d1d1", marginTop: '25px', marginBottom: '25px' }} />
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={6}>
                <Typography><b>Subtotal</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseInt(order.meta.subTotal).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
              </Grid>
            </Grid>
            {order.meta.fees &&
              <Fragment>
                {order.meta.fees.delivery &&
                  <Grid container style={{ marginTop: '10px' }}>
                    <Grid item xs={6}>
                      <Typography><b>Delivery Fee</b></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{order.meta.fees.delivery.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                    </Grid>
                  </Grid>
                }
                {order.meta.fees.freight &&
                  <Grid container style={{ marginTop: '10px' }}>
                    <Grid item xs={6}>
                      <Typography><b>Freight & Packing</b></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{order.meta.fees.freight.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                    </Grid>
                  </Grid>
                }
              </Fragment>
            }
            <Divider variant="middle" style={{ backgroundColor: "#d1d1d1", marginTop: '25px', marginBottom: '25px' }} />
            <Grid container style={{ marginTop: '10px' }}>
              <Grid item xs={6}>
                <Typography variant="h4"><b>Total:</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" style={{ textAlign: 'right', fontWeight: 'bold', marginTop: '10px' }}>&#8369;{parseInt(order.total).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
              </Grid>
            </Grid>
            {
              (!order.meta.fees && order.meta.type == 'cebu') &&
              <div style={{ display: 'flex', padding: '20px 0 10px 0' }}>
                <div style={{ marginRight: '5px' }}>
                  <InfoSharpIcon color="primary" />
                </div>
                <Typography style={{ textAlign: "justify" }}>Delivery is free within Cebu City, Talisay City, Mandaue City, Minglanilla, Naga City, Consolacion, and Liloan. Delivery outside these areas may incur an additional delivery charge (Between &#8369;250 to &#8369;500).</Typography>
              </div>
            }
            <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Order Info</Typography>
            <List className={classes.list}>
              <ListItem disableGutters className={classes.list}>
                <ListItemText primary="Type & Schedule" secondary={<span>{`${getType(order.meta.type)}`} <br/> {`${format(new Date(order.meta.date), 'EEE. MMM dd, yyyy')} ${format(new Date(order.meta.time), 'hh:mm a')}`} Philippine Standard Time (PST)</span>} />
              </ListItem>
              {order.meta.type == 'cebu' &&
                <ListItem disableGutters className={classes.list}>
                  <ListItemText primary="Address & Contact Person"
                    secondary={<span>{`${order.meta.unit ? `${order.meta.unit}, ` : ''}${order.meta.street}, ${order.meta.barangay}, ${order.meta.city}, CEBU`}<br />
                      {`${order.meta.firstName} ${order.meta.lastName}`}<br />
                      {`+63${order.meta.phone}`}</span>} />
                </ListItem>
              }
              {order.meta.type == 'airport' &&
                <ListItem disableGutters className={classes.list}>
                  <ListItemText primary="Airport & Contact Person" secondary={<span>{getAirport(order.meta.airport)}<br />
                    {`${order.meta.firstName} ${order.meta.lastName}`}<br />
                    {`+63${order.meta.phone}`}</span>} />
                </ListItem>
              }
              <ListItem disableGutters className={classes.list}>
                <ListItemText primary="Payment Method" secondary={getPayment(order.meta.payment.method)} />
              </ListItem>
              <ListItem disableGutters className={classes.list}>
                <ListItemText primary="Status" secondary={order.status.charAt(0).toUpperCase() + order.status.substring(1)} />
              </ListItem>
              <ListItem disableGutters className={classes.list}>
                <ListItemText primary="Order Creation" secondary={format(new Date(order.createdAt), "MMM dd, yyyy hh:mm a")} />
              </ListItem>
            </List>
            {(order.meta.reason && order.status == 'cancelled') &&
              <Typography style={{ color: red[600], marginBottom: '5px', fontWeight: 700 }}>
                {order.meta.reason}
              </Typography>
            }
            {(order.proof && order.meta.payment.method == 'bank' && order.status !== 'cancelled') &&
              <Fragment>
                {isDisabled() ?
                  <Fragment>
                    <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Proof of Payment</Typography>
                    <List>
                      <ListItem disableGutters className={classes.list}>
                        <ListItemText primary="Date of Deposit" secondary={format(new Date(order.proof.date), "MMM. dd, yyyy")} />
                      </ListItem>
                      <ListItem disableGutters className={classes.list}>
                        <ListItemText primary="Photo" />
                      </ListItem>
                    </List>
                    <div>
                      <img className={classes.selectedImg} src={img.url} />
                    </div>
                  </Fragment>
                  :
                  <Fragment>
                    <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Proof of Payment{order.status == 'rejected' && <Fragment> - <span style={{ color: red[600], fontWeight: 700 }}>(Action Required)</span></Fragment>}</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disableFuture
                        inputVariant="outlined"
                        label="Date of Deposit"
                        format="MM/dd/yyyy"
                        value={order.proof.date}
                        inputadornmentprops={{ position: "start" }}
                        onChange={(evt) => { handleDateChange(evt, 'proof.date') }}
                        className={classes.datePicker}
                      />
                    </MuiPickersUtilsProvider>

                    <Typography variant="subtitle2">
                      Upload proof of Payment (required)
                    </Typography>
                    <div>
                      <div className={errs.includes('photo') ? classes.imagePickerError : classes.imagePicker} onClick={choose}>
                        {img.url ?
                          <Fragment>
                            <img className={classes.selectedImg} src={`${img.url}`} />
                            <div className={classes.imgOverlay}>
                              <AddAPhotoIcon />
                              <Typography variant="h6">
                                Click to change photo
                              </Typography>
                            </div>
                          </Fragment>
                          :
                          <Fragment>
                            <div className={classes.imgOverlay}>
                              <AddAPhotoIcon />
                              <Typography variant="h6">
                                Click to add photo
                              </Typography>
                            </div>
                          </Fragment>
                        }

                      </div>
                      <input className={classes.inputFile} type="file" onChange={onChange} ref={input => chooser = input} />
                    </div>
                    {order.status == 'rejected' &&
                      <Typography style={{ color: red[600], marginBottom: '5px', fontWeight: 700 }}>
                        {order.meta.reason} <br />
                        Please clarify and re-upload your proof of payment.
                      </Typography>
                    }
                    {
                      <Button variant="contained" disabled={isLoading} color="primary" className={classes.loginButton} fullWidth onClick={checkOrder} >
                        Update
                      </Button>
                    }
                  </Fragment>
                }
              </Fragment>
            }

            {(order.meta.payment.method == 'xendit' && order.status == 'pending') &&
              <Button variant="contained" disabled={isLoading} color="primary" className={classes.loginButton} fullWidth onClick={continuePayment} >
                Continue Payment
              </Button>
            }

            {order.status == 'downpayment' &&
              <Button variant="contained" disabled={isLoading} color="primary" className={classes.loginButton} fullWidth onClick={continuePayment} >
                Pay Downpayment
              </Button>
            }
          </Fragment>
        }
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        message="Your order has been updated. We will send you a confirmation once your payment is confirmed."
      />
      {/* <pre>{JSON.stringify(order, null, 4)}</pre> */}
    </div>
  )

}

export default withRouter(UpdateOrder);
