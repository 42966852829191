import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import grey from '@material-ui/core/colors/grey';
import FinalRegisterForm from '../auth/FinalRegisterForm';

const useStyles = makeStyles(theme => (
    {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        textAlign: 'center',
        margin: '0.5em'
    }
  }));

const FinalRegister = props => {
    const classes = useStyles();
    return(
        <div className="content-paper">         
            <Typography className={classes.header} variant="h5">
                Complete Registration
            </Typography>
            <Typography className={classes.header} style={{marginBottom: '20px'}}>
                Please answer accurately,<br/> we only asked you once.
            </Typography>
            <FinalRegisterForm/>
        </div>
    )
}

export default FinalRegister;