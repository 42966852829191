import React from "react";
import {
  HashRouter as Router,
} from "react-router-dom";
import Routes from "./routes";

import theme from './theme';
import { ThemeProvider } from '@material-ui/styles';

import { AuthProvider } from "./auth/AuthContext";
import { OrderProvider } from "./store/OrderContext";
import { OrdersProvider } from "./store/OrdersContext";
import { SettingsProvider } from "./store/SettingsContext";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SettingsProvider>
          <OrderProvider>
            <OrdersProvider>
              <Router>
                <Routes />
              </Router>
            </OrdersProvider>
          </OrderProvider>
        </SettingsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;