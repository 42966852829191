import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import {crud} from '../services/crud';
import {withRouter} from 'react-router-dom';
import Profile from '../layouts/authenticated/components/sidebar/Profile';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {format} from 'date-fns';
import Viewer from 'react-viewer';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AuthContext} from '../auth/AuthContext';
import http from '../services/http';
import config from '../config/config';

  const useStyles = makeStyles(theme => (
    {
      table: {
        minWidth: 700,
      },
      container: {
          padding: '4em 1em 1em 1em',
          minHeight: '100vh',
          width: '100%',
          backgroundColor: '#ffffff'
      },
      button: {
        margin: '0.5em 0',
        background: '#e0e0e0',
        padding: "0.5em 1.5em"
    },
    close: {
      padding: theme.spacing(0.5),
    }
    }
  ));

const AdminOrder = (props) => {
    const {user} = React.useContext(AuthContext);
    const [order, setOrder] = React.useState({user: {meta: ''}, proof: {}, meta: {}});
    const [payment, setPayment] = React.useState({payID: 'sadsadsads'});
    const [visible, setVisible] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles();
    const orders = crud('orders');
    const payments = crud('payments');
    const orderId = props.match.params.id;
    const getOrder = async () => {
        let res = await orders.findOne(orderId);
        if(res) {
           setOrder(res.data);
        }
        if(res.data.meta.payment !== "BANK DEPOSIT") {
          let pay = await payments.find({orderId: orderId});
          if(pay) {
            setPayment(pay.data[0]);
          }
        }
    }

    const updateOrder = async() => {
      setIsLoading(true);
      let _order = {
        ...order,
        status: 'Verified',
        updatedBy: user.meta.name
      }
      let res = await orders.save(_order);
      if(res) {
        setMsg("Order Updated");
        setOpen(true);        
        getOrder();
      }

      setIsLoading(false);
    }

    const verifyOrder = async() => {
      if(!payment.payID) {
        setMsg("PayID is missing.");
        setOpen(true);
        return;
      }
      setIsLoading(true);
      let res = await http.get(config.app.server.url+"/verifyorder",{
       params: {
        id: payment.payID
       }
      });

      if(res) {
        setMsg("Payment Refreshed");
        setOpen(true);        
        getOrder();
        setIsLoading(false);
        return;
      }

      setMsg("Something went wrong.");
      setOpen(true);
      setIsLoading(false);
    }

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    React.useEffect( () => {
        getOrder();
    },[]);
    return(
<div className={classes.container}>
<Container maxWidth="md" component={Paper} style={{padding: '1.5em'}}>
    <Grid container>
        <Grid xs={12} md={4} item>
            {Object.keys(order).length > 0 &&
              <React.Fragment>
                <Profile user={order.user}/>
                <List>
                    <ListItem>
                        <ListItemText primary="Batch" secondary={order.user.meta.batch} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Phone Number" secondary={<a style={{fontWeight: '600', color: '#000'}} href={`tel:+63${order.user.meta.phone}`}>{`+63${order.user.meta.phone}`}</a>} />
                    </ListItem>
                    <ListItem>
                      {order.date &&
                            <ListItemText primary="Created" secondary={format(new Date(order.date), "MMM. dd, yyyy")} />
                      }
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Serial" secondary={order.serial} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Payment Method" secondary={order.meta.payment} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Status" secondary={order.status} />
                    </ListItem>
                    {order.status === 'Verified' && <ListItem>
                        <ListItemText primary="Verified By" secondary={order.updatedBy} />
                    </ListItem>}
                    
                </List>
              </React.Fragment>
            }
        </Grid>
            <Grid xs={12} md={8} item>
               {order.meta.payment !== "BANK DEPOSIT" ?
                  <Fragment>
                    <List>
                      <ListItem>
                          <ListItemText primary="Pay ID" secondary={payment.payID} />
                      </ListItem>
                      <ListItem>
                          <ListItemText primary="Status" secondary={payment.status} />
                      </ListItem>
                    </List>
                    { isLoading ? 
                      <CircularProgress />
                      :
                      <Button className={classes.button} onClick={verifyOrder}>Refresh</Button>
                    }
                  </Fragment>
                :
                Object.entries(order.proof).length > 0 ?

                  <Fragment>
                    <List>
                  <ListItem>
                  {order.proof.date &&
                          <ListItemText primary="Date of Deposit" secondary={format(new Date(order.proof.date), "MMM. dd, yyyy")} />
                  }
                  </ListItem>
                  <ListItem>
                      <ListItemText primary="Reference No." secondary={order.proof.ref} />
                  </ListItem>
                  <ListItem>
                      <ListItemText primary="Proof of Payment (Photo)"/>
                  </ListItem>
                  <img src={order.proof.photo} onClick={() => { setVisible(true); } } style={{width: '400px', cursor: 'pointer'}}/>
                  <Viewer
                    visible={visible}
                    onClose={() => { setVisible(false); } }
                    rotatable={false}
                    scalable={false}
                    zIndex={9999}
                    images={[{src: order.proof.photo, alt: 'proof'}]}
                    />
                  </List>
                 { isLoading ? 
                  <CircularProgress />
                   :
                   <Fragment>
                     {
                       order.status !== 'Verified' && order.meta.payment === 'BANK DEPOSIT' &&
                       <Button className={classes.button} onClick={updateOrder}>Accept</Button>
                     }
                    </Fragment>
                 } 
              
                  </Fragment>
                  :
                  <h3>No update yet.</h3>
                  
               }
            </Grid>
    </Grid>
  </Container>
  <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
</div>
    )
};

export default withRouter(AdminOrder);