import React, { Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import {StateHelper} from 'react-form-plus';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import grey from "@material-ui/core/colors/grey";
import leslie from '../../../assets/leslie-app-inverted.png';
import red from '@material-ui/core/colors/red';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",     
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
              },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
              },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            margin: '0 8px',
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '25px',
                fontWeight: '700',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        innerInput: {
            fontSize: '25px',
            fontWeight: '700',
            textAlign: 'center',
            borderBottom: '2px solid #cccccc'
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
        },
        descriptionText: {
            fontSize: '1.5em',
            margin: theme.spacing(2),
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        formControl: {
            margin: "1em 0",
            background: theme.palette.common.white
          },
        descriptionCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
             }
        },
        removeButton: {
            // borderRadius: '50%',
            // background: ,
            // display: 'flex',
            position: 'absolute',
            // height: '40px',
            // width: '40px',
            // justifyContent: 'center',
            // alignItems: 'center',
            top: '10px',
            right: '10px',
            '&:hover': {
                background: red[400],
            },
            '&:hover .MuiSvgIcon-root': {
                color: '#fff',
            },
        },
        innerPaper: {
            position: 'relative',
            padding: '30px 50px',
            borderRadius: '8px',
            margin: '20px 12px',
            backgroundColor: '#ffffff',
            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            [theme.breakpoints.down('md')]: {
                padding: '20px 30px',
            },
        }
    }));

const Content = props => {

    const { children, container = false } = props;
    const classes = useStyles();
 
    return (
        
        <div className={classes.orderContainer}>
            
          <div className={classes.grad1}>
                <Container>
                    <div className={classes.watchImgCont}>
                        <img className={classes.watchImg} src={leslie}/>
                        
                        <Typography variant="h5" style={{color: '#ffffff', maxWidth: '320px'}}>
                            <span style={{fontWeight: '800'}}>Leslie's Lechon</span> continues the legacy of the "Mother of the Inasal Industry in Talisay".
                        </Typography>
                    </div>
                </Container>
            </div>
        <Container maxWidth="sm" style={{padding: '20px 0', marginTop: "-200px"}}>
            {children}
        </Container>
        </div>
    )

}

export default Content;
