import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StateHelper } from 'react-form-plus';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import grey from "@material-ui/core/colors/grey";
import leslie from '../assets/leslie-app-inverted.png';
import Button from '@material-ui/core/Button';
import { format, addDays } from 'date-fns';
import queryString, { parse } from 'query-string';
import { crud } from '../services/crud';
import bankIcon from '../assets/bank.svg';
import completeIcon from '../assets/complete.svg';
import failIcon from '../assets/fail.svg';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
            },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
            },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '17px',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
            textAlign: 'justify'
        },
        descriptionText: {
            fontSize: '1.5em',
            margin: theme.spacing(2),
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        // formControl: {
        //     margin: "1em 0",
        //     background: theme.palette.common.white
        //   },
        descriptionCont: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
            }
        },
        toggleButtons: {
            display: 'flex',
            marginBottom: '5px'
        },
        toggleButton: {
            flex: 1,
            textAlign: 'center',
            padding: '10px',
            '&:hover': {
                cursor: 'pointer',
            },
            '&:hover .MuiTypography-root': {
                color: '#f26522'
            },
            '&:first-child': {
                borderRadius: '8px 0 0 8px',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:last-child': {
                borderRadius: ' 0 8px 8px 0',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderRight: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:not(:first-child):not(:last-child)': {
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:first-child:hover': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&:first-child:hover + &': {
                borderLeft: 'none'
            },
            '&:not(:first-child):not(:last-child):hover': {
                border: '2px solid #f26522',
            },
            '&:not(:first-child):not(:last-child):hover + &': {
                borderLeft: 'none'
            },
            '&:last-child:hover': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            }
        },
        link: {
            textDecoration: "none",
            fontWeight: 600,
            color: "#263238",
            "&:hover": {
                color: "#f26522"
            }
        },
        send: {
            maxHeight: '100px',
            margin: '15px auto 35px auto',
            display: 'block'
        },
    }));

const BankConfirm = props => {
    const { type, id } = queryString.parse(props.location.search);
    const classes = useStyles();
    const _date = addDays(new Date(), 5);
    const [order, setOrder] = useState(null);

    let promptTimeout = null;

    const getOrder = async () => {
        if (id == undefined) {
            return;
        }
        const _order = crud('orders');
        let res = await _order.findOne(id);
        if (res) {
            if (res.data) {
                setOrder({ ...res.data });
            }
        }
    };

    const checkout = () => {
        props.history.push("/complete/deposit?id=" + id);
    }

    const done = async () => {
        await showPrompt();
        props.history.push("/home");
    }

    const getExpiry = () => {
        if (!order) {
            return new Date();
        }

        let hours = 5;

        if (order.meta.type == 'airport') {
            hours = 6;
        }

        const d = new Date(order.meta.date);
        const t = new Date(order.meta.time);

        return new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            t.getHours() - hours,
            t.getMinutes(),
            0,
            0
        );
    }

    const showPrompt = async () => {
        if (!window.installPrompt) {
            return;
        }
        const result = await window.installPrompt.prompt();
        console.log(`Install prompt was: ${result.outcome}`);
    }

    useEffect(() => {
        getOrder();
    }, []);

    return (

        <div>
            <Fragment>
                <div className="content-paper">
                    <Typography>
                        {type == 'bank' &&
                            <div>
                                <img className={classes.send} src={bankIcon} />
                                <div className={classes.propertyText}>
                                    Thank you for purchasing at Leislie's Express. Please complete your payment before <b>{format(getExpiry(), "MMMM dd, yyyy hh:mm a")}</b> and send your details of your payment through <a className={classes.link} href="/#/orders">Pending Orders</a> page. Your order will be cancelled automatically if you are unable to complete the payment on time.
                                </div>
                            </div>
                        }
                        {type == 'completed' &&
                            <div>
                                <img className={classes.send} src={completeIcon} />
                                <div className={classes.propertyText}>
                                    Thank you for purchasing at Leislie's Express. Your order is confirmed and you will receive a notice once your order is ready.
                                </div>
                            </div>
                        }
                        {type == 'failed' &&
                            <div>
                                <img className={classes.send} src={failIcon} />
                                <div className={classes.propertyText}>
                                    We're sorry to inform you that your order is cancelled because your payment was unsuccessful. You may try again later!
                                </div>
                            </div>
                        }
                        {type == 'cod' &&
                            <div>
                                <img className={classes.send} src={completeIcon} />
                                <div className={classes.propertyText}>
                                    A LesliesExpress.ph personnel will be contacting you on your mobile number to confirm your order. You will receive a notice once your order is confirmed and please pay the amount to our friendly delivery personnel upon delivery of your order. Enjoy your day!
                                </div>
                            </div>
                        }
                        {type == 'pickup' &&
                            <div>
                                <img className={classes.send} src={completeIcon} />
                                <div className={classes.propertyText}>
                                    A LesliesExpress.ph personnel will be contacting you on your mobile number to confirm your order. You will receive a notice once your order is confirmed and please pay the amount to our friendly cashier when you pick up your order. Enjoy your day!
                                </div>
                            </div>
                        }
                    </Typography>
                </div>
                {type == 'bank' ?

                    <div style={{ width: '100%', padding: '10px 0 30px' }}>
                        <Button style={{ margin: 'auto', display: 'block' }} color="primary" variant="contained" size="large" onClick={checkout}>Continue</Button>
                    </div>
                    :
                    <div style={{ width: '100%', padding: '10px 0 30px' }}>
                        <Button style={{ margin: 'auto', display: 'block' }} color="primary" variant="contained" size="large" onClick={done}>Done</Button>
                    </div>
                }
            </Fragment>
            {/* <pre>{JSON.stringify(order, null, 4)}</pre> */}
        </div>
    )

}

export default withRouter(BankConfirm);
