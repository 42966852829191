import React from 'react';
import watch from '../assets/leslie-app-inverted.png';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import grey from "@material-ui/core/colors/grey"
import { withRouter } from "react-router-dom";
import { SettingsContext } from '../store/SettingsContext';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FacebookIcon from '@material-ui/icons/Facebook';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import { OrdersContext } from '../store/OrdersContext';

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '125px 0 20px',
            [theme.breakpoints.down('md')]: {
                padding: '14vw 0 20px',
            },
        },
        grad2: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            textAlign: 'center',
            color: '#fff',
            padding: '0 0 25px'
        },
        grad2Content: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            color: '#fff',
            // [theme.breakpoints.down('md')]: {
            //     alignItems: 'center',
            // }
        },
        watchImg: {
            maxWidth: "650px",
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
            },
            alignItems: 'center',
        },
        magisWatchImg: {
            display: 'block',
            width: '90%',
            height: 'auto',
            margin: 'auto auto auto 0',
            [theme.breakpoints.down('md')]: {
                margin: '4em auto 0 auto',
                width: '70%'
            }
        },
        magisWatchImgCont: {
            display: 'flex',
            height: '70vh',
            [theme.breakpoints.down('md')]: {
                order: '1',
                width: '100%',
                display: "flex",
                alignItems: "flex-start"
            },
            flex: '1'
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center',
            },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px 0 0'
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        logoImg: {
            width: '4em',
            margin: '1.5em 0.5em'
        },
        orderButton: {
            margin: '25px 0',
            '&:disabled': {
                background: grey[500],
            }
        },
        gridContainer: {
            flexGrow: 1,
            marginTop: '30px',
            maxWidth: '800px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        gridItem: {
            border: '3px solid #f26522',
            padding: '35px 10px',
            borderRadius: '8px',
            height: '100%',
            maxWidth: '250px',
            margin: 'auto'
        },
        link: {
            color: '#263238',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
                color: '#f26522'
            }
        }
    }));

const ColorCircularProgress = withStyles({
    root: {
        color: '#fff',
    },
})(CircularProgress);

const Home = (props) => {
    const classes = useStyles();
    const [ordered, setOrdered] = React.useState([]);
    const { orders } = React.useContext(OrdersContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);

    const gotoOrder = () => {
        props.history.push(`/order`);
    }

    const gotoOrders = () => {
        props.history.push(`/orders`);
    }

    const isSoldOut = () => {
        return ((settings.maxSerial - settings.minSerial) + 1) - ordered.length <= 0;
    }

    return (
        <div style={{ minHeight: '100vh' }}>
            <div className={classes.grad1}>
                <Container>
                    <div className={classes.watchImgCont}>
                        <img className={classes.watchImg} src={watch} />
                    </div>
                </Container>
            </div>
            <div className={classes.grad2}>
                <Container>
                    <div className={classes.grad2Content}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                                isLoading ?
                                    <div>
                                        <ColorCircularProgress />
                                    </div>
                                    :
                                    <div style={{ maxWidth: "280px" }}>
                                        <Button style={{ maxWidth: "160px" }} size="large" color="primary" className={classes.orderButton} onClick={gotoOrder} variant="contained" fullWidth disabled={isSoldOut()}>
                                            Order Now
                                        </Button>
                                    </div>
                            }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {orders > 0 &&
                                <Button onClick={gotoOrders} variant="outlined" color='primary' style={{ margin: '15px 0' }}>
                                    <ListAltSharpIcon style={{ marginRight: '10px' }} color='primary' />
                                    You have {orders} pending {orders > 1 ? "orders" : 'order'}
                                </Button>
                            }
                            <Typography variant="h5">
                                <span style={{ color: "#f26522", fontWeight: '800' }}>Leslie's Lechon</span> continues the legacy of the <br /> "Mother of the Inasal Industry in Talisay".
                            </Typography>
                            <br />
                            <Typography variant="h5">
                                Operated by <span style={{ color: "#f26522", fontWeight: '800' }}>Theresa Enjambre </span> and Bitesize Digital Group, Inc.
                            </Typography>
                            <br />
                            <Typography variant="h5">
                                <span style={{ color: "#f26522", fontWeight: '800' }}>Leslie's Lechon</span> was featured in the<br />Netflix Docuseries Street Food.
                            </Typography>
                        </div>

                        <Grid className={classes.gridContainer} container spacing={3}>
                            {/* <Grid item xs="12" sm="12" md="4" lg="4">
                                        <div className={classes.gridItem}>
                                            <Typography style={{textAlign: 'center'}} variant="h6">
                                                <PhoneIcon color="primary" style={{fontSize: '50px', marginBottom: '5px'}} /> <br /> Call Us
                                            </Typography>
                                            <Typography style={{textAlign: 'center', marginTop: '10px'}} variant="h5">
                                                <a className={classes.link} href="tel:2731844" target="_blank">(032) 273-1844</a><br/>
                                                <a className={classes.link} href="tel:+639085286364" target="_blank">(+63) 908-528-6364</a>
                                            </Typography>
                                        </div>
                                    </Grid> */}
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className={classes.gridItem}>
                                    <Typography style={{ textAlign: 'center' }} variant="h6">
                                        <LocationOnIcon color="primary" style={{ fontSize: '50px', marginBottom: '5px' }} /> <br /> Our Office
                                    </Typography>
                                    <Typography style={{ textAlign: 'center', marginTop: '10px' }} variant="h5">
                                        Zone 5-36, Burgos St., Dumlog, Talisay City, <br />Cebu, Philippines 6045
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className={classes.gridItem}>
                                    <Typography style={{ textAlign: 'center' }} variant="h6">
                                        <FacebookIcon color="primary" style={{ fontSize: '50px', marginBottom: '5px' }} /> <br /> Facebook
                                    </Typography>
                                    <Typography style={{ textAlign: 'center', marginTop: '10px' }} variant="h5">
                                        Our Official Page <br />
                                        Leslie's Lechon <br />
                                        <a className={classes.link} href="https://fb.com/Leslieslechon" target="_blank">fb.com/Leslieslechon</a>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                </Container>
            </div>
        </div>
    )
}

export default withRouter(Home);