import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StateHelper } from 'react-form-plus';
import { OrderContext } from '../store/OrderContext';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import grey from "@material-ui/core/colors/grey";
import { SettingsContext } from '../store/SettingsContext';
import { AuthContext } from '../auth/AuthContext';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { crud } from '../services/crud';
import { Divider, FormHelperText } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { format } from 'date-fns';
import airportList from '../assets/airports.json';
import { DialogContent, Dialog, DialogContentText } from '@material-ui/core';
import waitIcon from '../assets/wait.svg';
import errorIcon from '../assets/error.svg';

const fs = new StateHelper();

const useStyles = makeStyles(theme => (
    {
        grad1: {
            backgroundColor: '#f26522',
            width: '100%',
            display: 'flex',
            padding: '20px 0 170px',
        },
        watchImg: {
            maxWidth: "350px",
            display: 'block',
            marginBottom: '1em',
            [theme.breakpoints.down('md')]: {
                width: '90%'
            },
            alignItems: 'center',
        },
        watchImgCont: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.down('md')]: {
                // justifyContent: 'center',
            },
            height: '100%',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            padding: '20px',
            marginTop: '20px'
        },
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
                fontSize: '17px',
            },
            '& .MuiInputAdornment-root': {
                marginRight: '4px'
            },
            '& .MuiFormHelperText-contained': {
                margin: '8px 14px'
            },
            '& .MuiInput-underline:before': {
                border: 'none',
            }
        },
        displayFlex: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
        },
        width50: {
            width: '50%',
        },
        orderContainer: {
            minHeight: '100vh'
        },
        contentContainer: {
            width: '100%',
            margin: '0 auto',
        },
        propertyText: {
            fontSize: '1em',
            margin: '0',
        },
        descriptionText: {
            fontSize: '1.5em',
            margin: theme.spacing(2),
        },
        magisText: {
            fontSize: '18px',
            fontWeight: '500',
            margin: '0',
        },
        infoText: {
            fontWeight: '700',
            fontSize: '18px',
            margin: '0',
        },
        // formControl: {
        //     margin: "1em 0",
        //     background: theme.palette.common.white
        //   },
        descriptionCont: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        checkout: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        checkoutButton: {
            '&:disabled': {
                background: grey[500],
            }
        },
        toggleButtons: {
            display: 'flex',
            marginBottom: '5px'
        },
        toggleButton: {
            flex: 1,
            textAlign: 'center',
            padding: '10px',
            '&:hover': {
                cursor: 'pointer',
            },
            '&:hover .MuiTypography-root': {
                color: '#f26522'
            },
            '&:first-child': {
                borderRadius: '8px 0 0 8px',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:last-child': {
                borderRadius: ' 0 8px 8px 0',
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderRight: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:not(:first-child):not(:last-child)': {
                borderTop: '2px solid rgba(0,0,0,0.5)',
                borderBottom: '2px solid rgba(0,0,0,0.5)',
                borderLeft: '2px solid rgba(0,0,0,0.5)',
            },
            '&:first-child:hover': {
                borderRadius: '8px 0 0 8px',
                border: '2px solid #f26522'
            },
            '&:first-child:hover + &': {
                borderLeft: 'none'
            },
            '&:not(:first-child):not(:last-child):hover': {
                border: '2px solid #f26522',
            },
            '&:not(:first-child):not(:last-child):hover + &': {
                borderLeft: 'none'
            },
            '&:last-child:hover': {
                border: '2px solid #f26522',
                borderRadius: ' 0 8px 8px 0'
            }
        },
        list: {
            padding: '0',
            '& .MuiListItemText-root': {
                padding: '0'
            },
            '& .MuiListItemText-root .MuiListItemText-secondary': {
                fontSize: '14px',
                lineHeight: '20px',
                marginTop: '3px'
            }
        },
        send: {
            maxHeight: '100px',
            margin: '15px auto 20px auto',
            display: 'block'
        },
    }));

const Payment = props => {
    const classes = useStyles();
    const { user, setUser, whoAmI } = React.useContext(AuthContext);
    const _order = React.useContext(OrderContext);
    const [errors, setErrors] = React.useState([]);
    const [state, setState] = React.useState(_order.order);
    const [open, setOpen] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);
    const [haveError, setHaveError] = React.useState(false);
    const { settings } = React.useContext(SettingsContext);
    fs.useState(state, setState);

    let redirectTimeout;

    const checkout = async () => {
        if (!state.meta.payment) {
            setErrors(["payment"]);
            return;
        }

        setIsLoading(true);
        setOpen(true);

        const ord = crud('orders');
        await ord.save({
            ...state,
            meta: {
                ...state.meta
            },
            total: total()
        }).then(o => {
            if (o.data) {
                console.log(o.data);
                _order.setOrder({ items: [] });
                whoAmI();
                redirectTimeout = setTimeout(() => {
                    if (state.meta.payment.method == 'xendit') {
                        window.location = o.data.xendit.link;
                        return;
                    } else if (state.meta.payment.method == 'cod') {
                        if (state.meta.type == 'pickup') {
                            props.history.push('/bank/confirm?type=pickup');
                            return;
                        }
                        if (state.meta.type == 'cebu') {
                            props.history.push('/bank/confirm?type=cod');
                            return;
                        }
                    }
                }, 1000);
            }
        }).catch(err => {
            console.log(err);
            setHaveError(true);
        });
    }

    const total = () => {
        if (state.meta.fees) {
            if (state.meta.fees.delivery) {
                return state.total + state.meta.fees.delivery;
            } else if (state.meta.fees.freight) {
                return state.total + state.meta.fees.freight;
            }
        }
        return state.total;
    }

    const handleChange = (event) => {
        setState({
            ...state, meta: {
                ...state.meta,
                payment: {
                    method: event.target.value
                }
            }
        })
    }

    const ifDisabled = () => {
        // const d = new Date();
        // if(d.getHours() < 6) {
        //     return true;
        // }
        return false;
    }

    const getType = t => {
        switch (t) {
            case 'cebu': return 'Within Cebu'; break;
            case 'airport': return 'Airport to Airport'; break;
            case 'pickup': return 'Pickup'; break;
        }
    }

    const getAirport = a => {
        const _airport = airportList.filter((x) => {
            return x.iata_code == a
        });
        if (_airport.length > 0) {
            return `${_airport[0].iata_code} | ${_airport[0].name} - ${_airport[0].city}`;
        }
        return ''
    }

    useEffect(() => {
        if (_order.order.total == undefined) {
            props.history.replace('/home');
        }
        return () => {
            clearTimeout(redirectTimeout);
        }
    }, []);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e, reason) => {
        if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            return;
        }
        setOpen(false);
    };

    return (

        <div>
            {state.meta &&
                <Fragment>
                    <div className="content-paper">
                        <Stepper activeStep={2} alternativeLabel>
                            <Step>
                                <StepLabel>Select Items</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Address & Delivery</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Payment</StepLabel>
                            </Step>
                        </Stepper>

                        <div>
                            <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Order Summary</Typography>
                            <Typography className={classes.labels} variant="caption">
                                ITEMS
                            </Typography>
                            {
                                state.items.map((item, idx) => (
                                    <Fragment key={idx}>
                                        <Grid container style={{ marginTop: '10px' }}>
                                            <Grid item xs={4}>
                                                <Typography><b>{item.type == "whole" ? "Whole Lechon" : "Lechon Belly"}</b></Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {/* <Typography>X{item.qty}</Typography> */}
                                            </Grid>
                                            <Grid item xs={4}>

                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography style={{ paddingLeft: '10px' }}>{item.size.charAt(0).toUpperCase() + item.size.substring(1)} {item.type == 'belly' && `(${item.size.charAt(1)}KG)`} - {item.flavor == 'spicy' ? "Spicy" : "Original"}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography>X{item.qty}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{(item.qty * settings.prices[item.type][item.size].price[item.flavor]).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                ))
                            }
                            <Divider variant="middle" style={{ backgroundColor: "#d1d1d1", marginTop: '25px', marginBottom: '25px' }} />
                            <Grid container style={{ marginTop: '10px' }}>
                                <Grid item xs={6}>
                                    <Typography><b>Subtotal</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{state.meta.subTotal.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                                </Grid>
                            </Grid>
                            {state.meta.fees &&
                                <Fragment>
                                    {state.meta.fees.delivery &&
                                        <Grid container style={{ marginTop: '10px' }}>
                                            <Grid item xs={6}>
                                                <Typography><b>Delivery Fee</b></Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{state.meta.fees.delivery.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {state.meta.fees.freight &&
                                        <Grid container style={{ marginTop: '10px' }}>
                                            <Grid item xs={6}>
                                                <Typography><b>Freight & Packing</b></Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>{state.meta.fees.freight.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Fragment>
                            }
                            <Divider variant="middle" style={{ backgroundColor: "#d1d1d1", marginTop: '25px', marginBottom: '25px' }} />
                            <Grid container style={{ marginTop: '10px' }}>
                                <Grid item xs={6}>
                                    <Typography variant="h4"><b>Total:</b></Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h4" style={{ textAlign: 'right', fontWeight: 'bold', marginTop: '10px' }}>&#8369;{total().toLocaleString()}.00</Typography>
                                </Grid>
                            </Grid>
                            <Typography style={{ marginTop: '10px', fontWeight: '700' }} color="primary" variant="subtitle2">Order Info</Typography>
                            <List className={classes.list}>
                                <ListItem disableGutters className={classes.list}>
                                    <ListItemText variant="h5" primary="Type & Schedule" secondary={<span>{`${getType(state.meta.type)}`} <br/> {`${format(new Date(state.meta.date), 'EEE. MMM dd, yyyy')} ${format(new Date(state.meta.time), 'hh:mm a')}`} Philippine Standard Time (PST)</span>} />
                                </ListItem>
                                {state.meta.type == 'cebu' &&
                                    <ListItem disableGutters className={classes.list}>
                                        <ListItemText primary="Address & Contact Person"
                                            secondary={<span>{`${state.meta.unit ? `${state.meta.unit}, ` : ''}${state.meta.street}, ${state.meta.barangay}, ${state.meta.city}, CEBU`}<br />
                                                {`${state.meta.firstName} ${state.meta.lastName}`}<br />
                                                {`+63${state.meta.phone}`}</span>} />
                                    </ListItem>
                                }
                                {state.meta.type == 'airport' &&
                                    <ListItem disableGutters className={classes.list}>
                                        <ListItemText primary="Airport & Contact Person" secondary={<span>{getAirport(state.meta.airport)}<br />
                                            {`${state.meta.firstName} ${state.meta.lastName}`}<br />
                                            {`+63${state.meta.phone}`}</span>} />
                                    </ListItem>
                                }
                            </List>
                            {
                                (!state.meta.fees && state.meta.type == 'cebu') &&
                                <div style={{ display: 'flex', padding: '20px 0 10px 0' }}>
                                    <div style={{ marginRight: '5px' }}>
                                        <InfoSharpIcon color="primary" />
                                    </div>
                                    <Typography style={{ textAlign: "justify" }}>Delivery is free within Cebu City, Talisay City, Mandaue City, Minglanilla, Naga City, Consolacion, and Liloan. Delivery outside these areas may incur an additional delivery charge (Between &#8369;250 to &#8369;500).</Typography>
                                </div>
                            }
                            <Typography style={{ fontWeight: '700', marginTop: '10px' }} color="primary" variant="subtitle2">Payment Method</Typography>
                            <FormControl error={errors.includes("payment")}>
                                <RadioGroup
                                    aria-labelledby="payment-methond"
                                    name="payment-methond"
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="xendit" control={<Radio color="primary" />} label="Online Bank, Credit/Debit Card, E-Wallet" disabled={ifDisabled()} />
                                    <FormControlLabel value="cod" control={<Radio color="primary" />} label={state.meta.type == "cebu" ? "Cash on Delivery" : "Cash on Pickup"} disabled={state.meta.type == "airport"} />
                                </RadioGroup>
                                {errors.includes("payment") && <FormHelperText>
                                    Please select at least one payment method
                                </FormHelperText>}
                            </FormControl>
                        </div>
                    </div>
                    <div style={{ width: '100%', padding: '20px 30px', display: 'inline-block' }}>
                        <div style={{ float: 'left', width: '50%' }}>
                            <Button style={{ float: 'right', display: 'block', margin: '0 10px' }} variant="contained" size="large" onClick={() => { props.history.push('/address'); }}>Back</Button>
                        </div>
                        <div style={{ float: 'right', width: '50%' }}>
                            <Button disabled={isLoading} style={{ float: 'left', display: 'block', margin: '0 10px', minWidth: '85px' }} color="primary" variant="contained" size="large" onClick={checkout}>
                                {
                                    isLoading ?
                                        <CircularProgress size={20} />
                                        :
                                        'Next'
                                }
                            </Button>
                        </div>
                    </div>
                </Fragment>
            }

            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}

            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogContent>
                    {
                        haveError ?
                            <div style={{padding: '10px 15px', width: '100%'}}>
                                <img className={classes.send} src={errorIcon} />
                                <DialogContentText id="alert-dialog-description" style={{textAlign: "center"}}>
                                    <Typography variant='subtitle1'>Sorry, we're unable to process your order. Please try again later.</Typography><br/>
                                    <Typography variant='subtitle2'>If this error persists please contact us through<br/><a style={{textDecoration: 'none', color: '#f26522', fontWeight: 'bold'}} href="tel:+639085286364">+639085286364</a> or <a style={{textDecoration: 'none', color: '#f26522', fontWeight: 'bold'}} target="_blank" href="https://www.facebook.com/Leslieslechon">fb.com/Leslieslechon</a>.</Typography>  
                                </DialogContentText>
                                <Button color="primary" onClick={()=>{props.history.push('/');}} style={{margin: 'auto', display: 'block'}}>Go to Homepage</Button>
                            </div>
                            :
                            <div style={{padding: '10px 15px'}}>
                                <img className={classes.send} src={waitIcon} />
                                <DialogContentText id="alert-dialog-description" style={{textAlign: 'center'}}>
                                    <Typography variant='subtitle1'>Please wait as we process your order. This may take a few seconds.</Typography>
                                </DialogContentText>
                            </div>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default withRouter(Payment);
