import React from 'react';
import {AuthContext} from '../auth/AuthContext';

import {withRouter} from 'react-router-dom';
import firebase from '../auth/firebase';
import http from '../services/http';
import { OrdersContext } from '../store/OrdersContext';

var redirectTimeout;

const Logout = props => {
    const auth = React.useContext(AuthContext);
    const { setOrders } = React.useContext(OrdersContext);
    React.useEffect(() => {
        if(Object.keys(auth.user).length > 0) {
            setOrders(0);
            auth.setUser({});
            delete http.defaults.headers.common['Authorization'];
            firebase.firebase.auth().signOut();
        }
        redirectTimeout = setTimeout(()=>{
            props.history.push('/home');
        }, 500);

        return () => {
            clearTimeout(redirectTimeout);
        }
    });

    return (
        <span></span>
    )
}

export default withRouter(Logout);