import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {StateHelper} from 'react-form-plus';
import {OrderContext} from '../store/OrderContext';
import {withRouter} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const fs = new StateHelper();

const Order = props => {
    const [order, setOrder] = React.useState({batch: '1996', serial: ''});
    const _order = React.useContext(OrderContext);
    const [ordered, setOrdered] = React.useState([]);
    fs.useState(order, setOrder);
    const useStyles = makeStyles(theme => (
        {
            displayFlex: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                flexDirection: 'row',
            },
            width50: {
                width: '50%',
            },
            orderContainer: {
                background: '#021744',
                padding: '4em 1em',
                minHeight: '100vh'
            },
            contentContainer: {
                width: '100%',
                margin: '0 auto',
            },
            propertyText: {
                fontSize: '1.5em',
                color: '#fff',
                margin: '0',
            },
            descriptionText: {
                fontSize: '1.2em',
                color: '#fff',
                margin: theme.spacing(2),
                textAlign: 'justify'
            },
            magisText: {
                fontSize: '18px',
                color: '#fff',
                fontWeight: '500',
                margin: '0',
            },
            infoText: {
                fontWeight: '700',
                fontSize: '16px',
                color: '#fff',
                margin: '0',
            },
            watchImg: {
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    width: '50%'
                },
                margin: '1em auto',
                display: 'block'
            },
            formControl: {
                margin: "1em 0",
                background: theme.palette.common.white
              },
              descriptionCont: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              },
              checkout: {
                  padding: theme.spacing(2)
              }
        }));
    const classes = useStyles();
    return (
        <Fragment>
            <div className={classes.orderContainer}>
                <Container maxWidth="sm">
                    <Grid container>
                    <Grid item>
                        {/* <img src={watch} className={classes.watchImg} /> */}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item className={classes.descriptionCont}>
                            <div className={classes.descriptionText}>
                                SOMETHING WENT WRONG WITH YOUR PAYMENT. PLEASE TRY AGAIN.
                            </div>
                        </Grid>
                    </Grid>
                <div className={classes.checkout}>
                    <Button variant="contained" href="/home" fullWidth>
                    Done
                    </Button>
                </div>
                </Container>
            </div>
        </Fragment>
    )

}

export default withRouter(Order);
