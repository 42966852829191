import React from 'react';
import { Typography, Button } from "@material-ui/core";
import notFoundIcon from '../assets/notfound.svg';

const NotFound = () => {
    return (
        <div style={{ width: '100%', height: '100vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", background: '#f26522' }}>
            <img style={{ maxHeight: '200px', margin: '30px auto', display: 'block' }} src={notFoundIcon} />
            <Typography variant="h4" style={{ color: '#ffff' }}>PAGE NOT FOUND</Typography>
            <Typography variant="subtitle1" style={{ color: '#ffff' }}>Sorry but the page you are looking for does not exist.</Typography>
            <br/>
            <Button href="/" variant="contained">GO TO HOMEPAGE</Button>
        </div>
    )
}


export default NotFound;